.video-container {
  width: 100%;
  position: relative;
  height: 0;
  padding-top: calc(9 / 16 * 100%);

  > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}