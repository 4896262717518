.popup-menu {
  --menu-background: var(--gray-650);
  --hover-background: var(--gray-180);

  $block: &;
  position: relative;
  width: 14.25em;
  background: var(--menu-background);
  border-radius: 0.714em;
  padding: 1.3125em;

  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);

  .menu-button {
    margin-bottom: 1.5625em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &::after {
    position: absolute;
    bottom: -0.75em;
    right: 1.5em;
    left: auto;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid var(--menu-background);
  }

  &--bottomLeft {
    &::after {
      bottom: -0.75em;
      right: 1.5em;
      left: auto;
    }

    @media screen and (min-width: 46em) {
      &::after {
        bottom: -0.75em;
        right: auto;
        left: 1.5em;
      }
    }
  }

  &--topLeft {
    &::after {
      top: -0.75em;
      right: auto;
      left: 1.5em;
      transform: rotate(180deg);
    }
  }

  &--topRight {
    &::after {
      top: -0.75em;
      right: 1.5em;
      left: auto;
      transform: rotate(180deg);
    }
  }
}
