.sub-nav {
  --hover-background: var(--background-first);

  // Fixes the problem on mobile but enables scrolling
  // position: fixed;
  top: 0;
  background: var(--background-second);
  color: var(--text-color-secondary);
  padding: 1.875em;
  padding-top: 1.1em;

  @media screen and (min-width: 46em) {
    position: fixed;
    width: calc(var(--nav-width) - 6em);
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 0 0 var(--gap-L);
    padding: 0;
    flex-grow: 1;
  }

  &__headline,
  &__project {
    @include h3;
    color: var(--text-color);
    opacity: 0.5;
    margin-left: 0.875em;
  }

  &__project {
    margin: 0 0 0.5em;
    margin-left: 0.875em;
  }

  &__item {
    display: block;
    margin: 0.25em 0;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5em 0.375em 0.5em 0.75em;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    transition: var(--hover-transition);

    &:hover {
      background: var(--hover-background);
      color: var(--white);
    }

    &:focus {
      outline: none;
      transition: none;
      color: var(--white);
    }

    &--active {
      background: var(--hover-background);
      color: var(--highlight);

      &:hover,
      &:focus {
        color: var(--highlight);
      }
    }

    &--header {
      padding: 0 0.75em;
    }

    &--inactive {
      opacity: 0.2;
      background: none;
      color: inherit;

      &:hover {
        background: none;
        color: inherit;
        opacity: 0.2;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25em;
    height: 1.25em;
    margin-right: 1.125em;
    color: inherit;

    svg {
      font-size: 1.5em;
    }
  }

  svg {
    color: var(--text-color-secondary);
  }

  &__back {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--white-opacity);
    font-size: 0.9em;
    margin-left: 0.875em;
    margin-bottom: var(--gap-L);
    transition: var(--hover-transition);

    .icon {
      font-size: 1.2rem;
      margin-right: 0.3em;
    }

    &:hover {
      color: var(--white);
    }
  }

  &__label {
    @include copy-small;
    line-height: 1.2;
    white-space: nowrap;
  }

  &__number {
    opacity: 0.6;
  }

  &__progress {
    position: absolute;
    width: 2.25rem;
    left: 0.25rem;
  }
}
