.table {
  width: 100%;
  border-spacing: 0;

  & thead {
    @include copy-small;
    text-align: center;
  }

  &__column-header {
    white-space: nowrap;
  }

  &__row-data {
    padding: 0.5em 1em ;
    text-align: center;
    width: 7em;

    & .toggle-switch__label{
      margin: auto;
    }
  }

  &__row-description{
    @include h2;
    padding: 0.5em 1em;
    text-align: left;

    @media screen and (min-width: 46em) {
      padding: 0.5em 2em;
    }
  }

  & tbody tr:nth-child(odd){
    background-color: var(--gray-600);
  }
}