.action-overlay-select-button {
  display: block;
  overflow: hidden;
  z-index: 10;

  line-height: 1;
  width: 3.375em;
  height: 3.375em;
  background: rgba(49, 50, 74, 0.5);
  color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 3px 4px rgba(255, 255, 255, 0.125);
  }

  &--selected {
    background: rgba(49, 50, 74, 1);

    color: var(--bright-green);
  }

  &:focus {
    outline: none;
  }
}
