.main-button {
  --button-primary: var(--highlight);
  --button-secondary: transparent;
  --button-color: var(--text-color);
  --button-border: var(--text-color-secondary);
  --button-disabled: var(--gray-650);

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include h3;
  line-height: 1.5;
  padding: 1.1rem;
  min-height: calc(1em + 1.1rem + 1.1rem + 2px);
  border: solid 1px transparent;
  width: auto;
  min-width: 11.875rem;
  background: var(--button-primary);
  color: var(--button-color);
  border-radius: 0.714em;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;

  // to be used as a link as well
  text-decoration: none;

  &:hover {
    // Transparent Border Fix
    background: var(--gradient-primary);
    background-size: calc(100% + 2px) calc(100% + 2px);
    background-position: -1px -1px;
  }

  &:focus {
    outline: 1px dashed var(--white);
    outline-offset: -0.45em;
  }

  &--secondary {
    background: var(--button-secondary);
    border-color: var(--button-border);

    &:hover {
      border-color: transparent;
    }
  }

  &--progress {
    background: linear-gradient(90deg, var(--button-primary) var(--button-progress, 100%), 0, rgba(253,45,85,0.6));
    background-size: calc(100% + 4px) 100%;
    background-position: -2px;
  }

  &:disabled {
    background: var(--button-disabled);
    cursor: not-allowed;
  }
}
