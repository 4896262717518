.company-logo {
  display: block;
  position: relative;
  @include copy-small;
  line-height: 1;
  width: 3.375rem;
  min-width: 3.375rem;
  //max-width: 3.375rem; // breaks layout
  height: 3.375rem;
  background: var(--preview-background);
  border-radius: 0.714em;
  overflow: hidden;

  &__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:focus {
    outline: 1px dashed var(--white-opacity);
    outline-offset: 0.25em;
  }

  &--big {
    min-width: 5em;
    height: 5em;
  }

  &--small {
    width: 2.25em;
    min-width: 2.25em;
  }
}