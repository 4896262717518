.page-logoHeader {
  $block: &;

  display: flex;
  width: 100%;

  &--list {
    #{$block}__title {
      @include h2;
    }

    #{$block}__subtitle {
      @include copy-large;
      font-weight: 300;
      line-height: 1.46;
      text-transform: unset;
      font-style: unset;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      margin-top: 0;
    }
  }

  &--big {
    #{$block}__title {
      @include extralarge-title;
    }
  }

  &--extraPadding {
    padding-bottom: 2em;
  }

  &--edit {
    &:hover {
      cursor: pointer;

      #{$block}__edit {
        opacity: 1;
      }
    }
  }

  &__edit {
    display: none;
    opacity: 0;
    width: 1em;
    height: 1em;
    padding: 0 0.5em;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    color: var(--white);
    transition: var(--hover-transition);

    &:focus {
      outline: none;
    }

    @media screen and (min-width: 46em) {
      display: inline-block;
    }
  }

  &__strategy {
    font-style: italic;
    text-transform: uppercase;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex-grow: 9999;
  }

  &__title {
    display: block;
    @include large-title;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  &__subtitle {
    @include h2;
    line-height: 1;
    margin-top: 0.5em;
  }

  .company-logo {
    align-self: center;
    margin-right: var(--gap-S);
  }
}
