.timer {
  $block: &;

  display: flex;
  align-items: center;
  background: var(--gray-250);
  color: var(--white);
  height: 3.375em;
  min-width: 8.875rem;
  border-radius: 10rem;
  padding-right: 1.375rem;
  padding-left: 1rem;


  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  &__body {
    font-size: 0.75rem;
    line-height: 1.333;
    font-weight: bold;
    font-style: italic;
  }

  &--running #{$block}__body {
    font-size: 1.25rem;
  }

  &--highlight {
    background: var(--bright-green);
  }

  &--highlight #{$block}__body {
    width: 100%;
    text-align: center;
  }
}
