.card-top {
  --card-background: var(--gray-250);
  --subtitle-color: var(--white-opacity);
  $rainbow-card-layout-switch: 56em;

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 6.5em;
  background: var(--card-background);
  border-top-left-radius: 0.625em;
  border-top-right-radius: 0.625em;
  padding: 1.3em var(--gap-S) 2.5em;
  margin-bottom: -1em;

  *:not(.rainbow-card--unresponsive) > & {
    @media screen and (min-width: $rainbow-card-layout-switch) {
      position: absolute;
      top: 0;
      left: 0;
      margin-bottom: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 9999;
  }

  &__title {
    @include h1;
    font-size: 1.125em;
  }

  &__subtitle {
    @include h2;
    line-height: 1;
    text-transform: unset;
    margin-top: 0.5em;
    font-style: normal;
    font-weight: normal;
    color: var(--subtitle-color);
  }

  .company-logo {
    align-self: center;
    margin-right: var(--gap-S);
  }
}