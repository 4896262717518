.page-switch {
  position: absolute;
  top: 24em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 10;

  &__control {
    width: 3em;
    height: 12em;
    background: var(--gray-70);
    opacity: 0.4;
    color: var(--white);
    transition: var(--hover-transition);
    pointer-events: auto;

    &--hidden {
      background: transparent;
    }

    .icon {
      font-size: 2.2em;
    }

    &:hover {
      opacity: 1;
    }

    &:focus {
      transition: none;
    }
  }
}