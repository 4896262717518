.tablist {
    --scrollbarBG: var(--gray-250);
    --thumbBG: var(--gray-110);
    $block: &;

    display: flex;
    margin-bottom: 4em;
    padding-bottom: 1em;
    width: 100%;
    max-width: 90vw;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
        width: 11px;
      }

    &::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG) ;
        border-radius: 6px;
        border: 4px solid var(--scrollbarBG);
    }

    &--small {
        margin-bottom: 2em;
    }

    &--search {
        justify-content: left;
        transform: translateY(-1em);

        @media screen and (min-width: 46em) {
            justify-content: center;
          }
    }

    & > * {
        margin-right: 2em;

        &:last-child {
            margin-right: 0;
        }
    }

    &--project-list {
        margin-bottom: 0;
        margin-right: 1rem;

        cursor: pointer;

        & > * {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__button {
        padding: 0 0.5em;
        padding-bottom: 0.8em;
        border-bottom: 1px solid transparent;
        color: var(--white-opacity);
        text-decoration: none;
        transition: var(--hover-transition);

        &:hover {
            color: var(--white);
        }

        &:focus, &--active {
            border-bottom: 1px solid var(--highlight);
            outline: none;
            color: var(--white);
        }
    }
}