// Quick fix for some issues with the responsive design
main {
  overflow-x: hidden;
}

$padding: 1em;
$half-padding: #{calc($padding / 2)};

.card-slider {
  $block: &;

  &__wrapper {
    display: grid;
    margin-left: -#{$half-padding};
    margin-right: -#{$half-padding};
  }

  &__controls,
  &__items {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  &--size-normal {
    #{$block}__wrapper {
      width: calc(100vw - 1.75em);

      @media (min-width: 23.125em) {
        width: calc(100% + #{$padding});
      }
    }

    #{$block}__controls {
      max-width: calc(100vw + #{$padding});

      @media (max-width: 45.875em) {
        margin: -1px -1em;
      }

      @media (min-width: 45.875em) {
        margin: -1px calc((100% - 100vw) / 2 - 0.5em);
      }

      @media (min-width: 70.5em) {
        margin: -1px calc((100% - 100vw) / 2 + 10.28em);
      }

      @media (min-width: 93.9375em) {
        margin: -1px calc((100% - 100vw) / 2 + 10.27em);
      }
    }
  }

  &--size-large {
    #{$block}__wrapper {
      width: calc(100vw);

      @media (min-width: 23.125em) {
        width: calc(100% + #{$padding});
      }
    }

    #{$block}__controls {
      padding: 0;
      margin-left: -0.375em;

      @media (max-width: 57.9375em) {
        width: 100vw;
      }

      @media (min-width: 46em) {
        margin-left: -1.375em;
      }

      @media (min-width: 57.9375em) {
        margin-left: calc((100% - 100vw) / 2);
        margin-right: calc((100% - 100vw) / 2);
      }

      @media (min-width: 70.5em) {
        margin-left: calc((100% - 100vw) / 2 - -3em);
        margin-right: calc((100% - 100vw) / 2 - -3em);
      }
    }
  }

  &__controls {
    z-index: 2;
    pointer-events: none;
    padding-left: #{$half-padding};
    padding-right: #{$half-padding};
    visibility: hidden;

    @media (min-width: 30em) {
      visibility: visible;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &__control {
    width: 80px;
    height: 100%;
    appearance: none;
    opacity: 0.87;
    text-indent: -999em;
    pointer-events: auto;
    background-color: var(--gray-110);
    background-position: center 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    pointer-events: auto;
    width: 1em;

    &:focus {
      outline: none;
    }

    @media (min-width: 37.5em) {
      width: 2em;
    }

    @media (min-width: 60em) {
      width: 4.625em;
    }

    @media (min-width: 70em) {
      width: 2.5em;
    }

    @media (min-width: 81.25em) {
      width: 4.625em;
    }

    &--left {
      float: left;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="chevron-left" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><polygon id="Path" fill="%23fff" fill-rule="nonzero" points="14.902 2 16.999 4.356 11.68 10.333 10.253 12 11.681 13.667 17 19.643 14.902 22 6 12"/></g></svg>');
    }

    &--right {
      float: right;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="chevron-right" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><polygon id="Path" fill="%23fff" fill-rule="nonzero" points="9.098 2 7.001 4.356 12.32 10.333 13.747 12 12.319 13.667 7 19.643 9.098 22 18 12"/></g></svg>');
    }

    &--hidden {
      display: none;
    }
  }

  &__items {
    z-index: 1;

    &--animated {
      transition: transform ease-in-out 200ms;
    }
  }

  &__item {
    max-width: calc(100vw - #{$padding});
    padding: 0 #{$half-padding};
    vertical-align: bottom;
    height: auto;
    display: inline-block;
    transition: transform ease-in-out 200ms;

    > * {
      width: 100%;
      max-width: 100%;
    }

    &--full-height {
      height: 100%;
    }
  }
}
