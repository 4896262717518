.card-wrapper {
  --card-background-primary: var(--gradient-primary);
  --card-background-secondary: var(--gray-250);
  --card-background-subtle: var(--gray-180);

  position: relative;
  padding: var(--gap-S);
  background: var(--card-background-secondary);
  border-radius: 0.625em;

  &--gradient {
    background: var(--card-background-primary);
  }

  &--subtle {
    background: var(--card-background-subtle);
  }

  &--top {
    padding: 8.7em var(--gap-S) var(--gap-S);
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background: var(--white-focus);
    }

    &:focus {
      outline: none;
      background: var(--white-focus);
    }
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;
  }
}