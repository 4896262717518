.user-flow-card {
  $block: &;
  $rainbow-card-layout-switch: 46em;

  --text-color: var(--white);
  --card-background-primary: var(--gradient-primary);
  --card-background-secondary: var(--gray-250);
  --image-height: 17.5em;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.125rem;
  height: 11.125rem;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-style: italic;

  @media screen and (min-width: $rainbow-card-layout-switch) {
    --image-height: 25.25em;
    flex-direction: row-reverse;
  }

  &--with-image {
    #{$block}__body {
      @media screen and (min-width: $rainbow-card-layout-switch) {
        padding-right: 20em;
        margin-right: -18em;
      }
    }
  }

  .card-header {
    margin-bottom: 1em;
    font-style: italic;
    font-weight: bold;
    color: var(--gray-900);
    -webkit-font-smoothing: antialiased;

    .card-header__title {
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;

      &--simple {
        color: var(--gray-900);
      }
    }
  }

  &__body {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--gap-S) var(--gap-S) 1em;
    border-radius: 0.625em;
    background: var(--card-background-secondary);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 999;
    max-height: 10em;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }

  &__text {
    @include copy-small;
    padding: 0 0 1.214em;
  }

  &__image {
    flex-basis: 12.25em;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    pointer-events: none;
    width: 12.25em;

    @media screen and (min-width: $rainbow-card-layout-switch) {
      margin-top: -2em;
      margin-right: 5.75em;
    }
  }

  &__headline {
    display: grid;
    position: absolute;
    top: 0;
    width: 100%;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 3rem;
    justify-items: center;
    align-items: center;
    row-gap: 0.125rem;

    > :nth-child(1) {
      display: flex;
      grid-column: 1 / 3;
      text-align: center;
      grid-row: 1/1;
      justify-content: center;
      text-transform: uppercase;
    }

    > :nth-child(2) {
      display: flex;
      background: transparent;
      color: white;
      grid-column: 2/2;
      grid-row: 1/1;
    }

    > :nth-child(3) {
      display: flex;
      grid-row: 2/2;
      grid-column: 1/3;
      width: 100%;
      height: 100%;
      align-self: flex-start;
      justify-content: center;

      > * {
        height: 100%;
      }
    }
  }

  &__phone {
    width: 100%;
    max-height: var(--image-height);
    position: relative;

    img {
      width: 100%;
      max-height: var(--image-height);
      object-fit: cover;
      object-position: center top;
    }

    &:after {
      content: '';
      position: absolute;
      left: -0.75em;
      top: -0.625em;
      right: -0.75em;
      bottom: 0;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 448"><g fill="none" fill-rule="evenodd"><path fill="%23313142" d="M190.4 0a30 30 0 0130 30v69.5h.1c.8 0 1.5.7 1.5 1.5v27c0 .8-.7 1.5-1.5 1.5V418a30 30 0 01-30 30h-159a30 30 0 01-30-30V170.4h-.1c-.8 0-1.4-.7-1.4-1.5v-29c0-.9.7-1.5 1.5-1.5v-8.9h-.1c-.8 0-1.4-.7-1.4-1.5V99c0-.9.7-1.5 1.5-1.5V75.3h-.1c-.8 0-1.4-.7-1.4-1.5V60.3c0-.9.7-1.5 1.5-1.5V30a30 30 0 0130-30h159zM33 12a20 20 0 00-20 19.7V417a20 20 0 0019.7 20H189a20 20 0 0020-19.7V32a20 20 0 00-19.7-20h-19.5.2c-2 0-4.5.5-6 1.5-1.4 1-2 2.8-2 3.4v.1a6 6 0 01-6 6H67a6 6 0 01-6-5.8V17c0-.5-.5-2.5-2-3.5S55 12 53 12h.2H33z"/><path fill="%23D8D8D8" d="M127 11a3 3 0 110 6 3 3 0 010-6zm-12 2a1 1 0 110 2H94a1 1 0 010-2h21z"/></g></svg>');
      background-size: cover;
      box-shadow: 0 0.5em 2.8125em rgba(0, 0, 0, 0.1),
        0 0 1em rgba(0, 0, 0, 0.1) inset;
    }
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s;
    z-index: 10;

    @media screen and (min-width: 65em) {
      visibility: hidden;
      opacity: 0;
    }
  }

  textarea {
    background: var(--gray-180);
    width: 100%;
    height: calc(1.857em * 3 + 1.5em);
    color: var(--gray-900);
    font-weight: bold;
    font-style: italic;
    border: 0;
    padding: 0.5rem 0.75rem;
    -webkit-font-smoothing: antialiased;

    &:disabled {
      background: transparent;
      color: var(--white);
      resize: none;
    }
  }

  &:hover {
    .destroy-button {
      visibility: visible;
      opacity: 1;
    }
  }
}
