.add-button {
  --button-background: var(--gray-250);
  --button-text: var(--white-opacity);

  display: block;
  position: relative;
  overflow: hidden;
  justify-content: center;

  @include copy-small;
  line-height: 1;
  width: 100%;
  max-width: calc(19.857em + 2px);
  height: calc(16.357em + 2px);
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 0.714em;
  border: 1px dashed var(--button-text);
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: var(--hover-transition);

  &--fill-container {
    max-width: initial;
    height: 100%;
  }

  &--screen {
    margin-top: 8.5em;
    height: calc(30rem + 2px);
    max-width: calc(13.875rem + 2px);

    @media (min-width: 542px) {
      margin-top: 22.5em;
    }
  }

  &--user-flow {
    height: 11.125rem;
    max-height: 11.125rem;
  }

  .icon {
    font-size: 2em;
    margin-bottom: 0;
  }

  &:hover {
    --button-text: var(--white);
  }

  &:focus {
    --button-text: var(--white);
    outline: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin: 0.39em 0;
    }
  }

  &__text {
    display: inline-block;
    line-height: 1.43;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--button-text);
  }
}
