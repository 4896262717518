.l-item-stack {
  display: grid;
  grid-gap: 1em;
  justify-items: start;

  &--spaced {
    justify-items: inherit;
  }

  &--center {
    justify-items: center;
  }
}

.l-item-stack-horizontal {
  display: flex;
  align-items: center;

  > *,
  > h2 {
    display: flex;

    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }

  &--space-between {
    justify-content: space-between;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  &--at-top {
    align-items: flex-start;
  }
}

.l-item-stack-regular {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > * {
    flex-grow: 1;
  }
}
