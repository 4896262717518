.l-project-wrapper {
  $content-breakpoint: 46em;

  // display: grid;
  // grid-template-columns: 1fr minmax(auto, 64em) 1fr;
  width: 100%;
  padding: 0 2em 4em;
  margin-top: 2em;

  @media screen and (min-width: $content-breakpoint) {
    padding: 0em 2em 4em;
  }

  @media screen and (min-width: 76.5em) {
    margin: 0 auto;
    max-width: 64em;
  }

  > .tag-list {
    transform: translateY(-2.5em);
  }

  > * {
    grid-column: 2;
  }

  &--extend {
    grid-column: 1/-1;
  }

  .section-headline {
    &:first-of-type {
      padding-top: 0;
    }
  }
}