.list-menu {
  $block: &;
  position: relative;

  &__menu {
    .popup-menu {
      position: absolute;
      bottom: 4.5em;
      left: -11.5em;
      width: 15.25em;
    }
  }

  [hidden] {
    display: none;
  }

  &--bottomLeft {
    #{$block}__menu {
      .popup-menu {
        bottom: 4.5em;
        left: -0.4em;
      }
    }
  }

  &--topLeft {
    #{$block}__menu {
      .popup-menu {
        top: 2em;
        bottom: auto;
        left: -1.7em;
      }
    }
  }

  &--topRight {
    #{$block}__menu {
      .popup-menu {
        top: 2em;
        bottom: auto;
        right: -1.7em;
      }
    }
  }
}
