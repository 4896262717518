.avatar-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bubble {
    position: relative;
    z-index: 2;
  }
  .avatar {
    width: 7.5rem;
    margin-top: -0.25rem;
  }
}
