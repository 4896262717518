.add-card-button {
  font-size: 0.875em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: var(--text-color);
  border: 1px dashed var(--text-color);
  border-radius: 50%;
  opacity: 0.5;
  transition: all var(--hover-transition);
  padding: 0.65em;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
    outline: none;
    transition: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    height: 1.6em;
    width: 1.6em;
  }
}
