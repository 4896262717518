.search-and-counter-bar {
  hr {
    margin: 0.75rem 0;
  }
  display: grid;
  grid-template-columns: 285px 1fr 285px;
  align-items: center;
  background: var(--background-second);
  height: 45px;
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 5px;
  align-items: flex-start;
  &::after {
    content: '';
    position: fixed;

    background-color: transparent;
    top: 45px;
    height: 50px;
    width: 25px;
    border-top-left-radius: 0.625em;
    z-index: 99;
    box-shadow: 0 -0.625em 0 0 var(--background-second);
  }

  &--dark {
    background: var(--background-third);

    &::after {
      box-shadow: 0 -0.625em 0 0 var(--background-third);
    }
  }

  @media screen and (max-width: 65em) {
    display: none;
  }

  &__aiButton {
    grid-column: 3/4;
    justify-content: flex-end;
    display: flex;
    padding-right: 20px;
  }

  .search-screen__search-form {
    display: flex;
    margin-top: 0;
    grid-column: 2/3;
    width: 100%;
    height: 33px;
    justify-content: center;
    z-index: 100;

    @media screen and (max-width: 1300px) {
      grid-column: 1/3;
      justify-content: flex-end;
    }
  }

  .search-form__fieldset {
    height: 33px;
    border-radius: 6px;
    font-size: small;
    max-width: 35rem;
    height: auto;
  }

  .response-counter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--text-color);
    font-weight: 800;
    font-style: italic;
    font-size: 14px;
    text-transform: uppercase;
    padding-right: 0.5rem;
  }

  .request-hover ::-webkit-details-marker {
    display: none;
  }

  .request-hover summary {
    list-style: none;
    cursor: pointer;
  }

  .request-status-heading {
    margin-bottom: 0.25rem;
  }
  details[open] {
    padding: 6px 0;
    padding-bottom: 1rem;
  }

  details[open] > summary::after {
    transform: rotate(-180deg);
    top: 2px;
  }

  summary::after {
    margin-left: 0.85ch;
    font-style: normal;
    display: inline-block;
    content: url('data:image/svg+xml;utf8,<svg width="10" height="7" viewBox="0 0 8 5" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M0.94 0L4 3.09042L7.06 0L8 0.951417L4 5L0 0.951417L0.94 0Z" fill="white"/></svg>');
    transition: 0.2s;
    position: relative;
    top: -2px;
  }

  .list-item {
    font-style: normal;
    font-weight: 400;
    text-transform: none;
  }

  .list-item.done {
    cursor: pointer;

    display: flex;
    justify-content: space-between;

    .icon {
      margin-top: 0.2rem;
      margin-right: 0.2rem;
    }
  }
  .done:hover {
    background-color: var(--background-second);
  }
}
