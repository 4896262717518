.settings-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #E94E77;

  &:last-child {
    border-bottom: none;
  }
}

.settings-title {
  font-size: 1em;
  color: var(--white);
  font-weight: 400;
  margin: 0;
}

.settings-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &-label {
    color: var(--white-opacity);
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &-input {
    width: 3rem;
    text-align: center;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1em;
  }
}

.settings-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.settings-button {
  padding: 0.2rem 0.75rem;
  border-radius: 2rem;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background: #E94E77;
    border-color: #E94E77;
  }
}

.settings-divider {
  position: relative;
  text-align: center;
  color: #fff;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &::before,
  &::after {
    content: '';
    width: 10%;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
  }
}