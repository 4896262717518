.logo-with-thumbnail {
  position: relative;
  padding: 0.5rem 0.5rem 0;

  &__logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 2.25rem;

    > * {
      min-width: 2.25rem;
      width: 2.25rem;
      min-height: 2.25rem;
      height: 2.25rem;
    }
  }

  &__logo-large {
    position: absolute;
    left: -0.5rem;
    top: -0.75rem;
    width: 4.5rem;

    > * {
      min-width: 4.5rem;
      width: 4.5rem;
      min-height: 4.5rem;
      height: 4.5rem;
    }
  }

  .destroy-button {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    opacity: 0;
    visibility: hidden;
  }

  &:hover .destroy-button {
    opacity: 1;
    visibility: visible;
  }
}
