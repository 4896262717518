.action-overlay-button {
  --text-color: var(--white);
  --text-color-opacity: var(--white-opacity);
  --background-color: var(--gray-300);

  @include h3;
  line-height: 1.25;
  font-size: 0.7rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
  gap: var(--space-s);
  padding: var(--space-s) var(--space-m);
  background: var(--gray-300);
  border-radius: var(--space-s);
  justify-content: space-evenly;
  max-width: 9rem;

  &--inline {
    flex-direction: row;
    text-align: left;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }

  .icon {
    height: 1.75em;
    width: 1.75em;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--text-color-opacity);
  }

  &:focus {
    outline: 1px dashed var(--white-opacity);
    outline-offset: 0.5em;
  }
}
