.card-grid {
  //padding: 5em 0 0;
  overflow: hidden;
  // transform: translateY(-5em);

  @media screen and (min-width: 46em) {
    overflow: visible;
  }

  .rainbow-card {
    padding-top: 0;
    height: 20.625em;
  }

  &__items {
    display: grid;
    grid-gap: 11px;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--twoColumns {
      @media screen and (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__item {
    display: flex;
    justify-items: center;
    align-items: center;
  }
}
