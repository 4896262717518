.input-state {
  position: relative;

  &__error {
    position: relative;
    display: flex;
    padding: 1em 1.1em;
    background: var(--highlight);
    z-index: 2;
  }

  &__sucess {
    position: relative;
    display: flex;
    padding: 1em 1.1em;
    background: var(--bright-green);
    color: var(--gray-180);
    z-index: 2;
  }
}
