.floating-search-button {
    position: relative;
    margin-bottom: 0.5em;

    &__form {
        position: absolute;
        right: 0;
        top: 0;
        width: 600px;
        max-width: 50vw;
        z-index: -1;
        transition: width .1s;
        max-width: calc(100vw - 4em);

        &--shrink {
            width: 3.375em;
        }
    }
    @media screen and (min-width: 70.5em) {
        display: none;
    }
}
