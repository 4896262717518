.info-bubble {
  $block: &;

  @include h2;
  font-size: 0.8em;
  line-height: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 0.9rem;
  height: 0.9rem;
  margin: 0 0.5rem;
  background: var(--bright-pink);
  color: var(--white);
  border-radius: 50%;
  cursor: help;

  &:hover {
    #{$block}__text {
      display: block;
    }
  }

  &__text {
    position: absolute;
    bottom: 120%;
    left: 0;
    display: none;
    width: 10rem;
    height: 10rem;
    background: var(--gray-650);
    padding: 1rem;
    border-radius: 5px;
  }
}
