.card-menu {
  $block: &;
  display: flex;
  align-items: center;

  &--small {
    #{$block}__avatar {
      margin-right: 0.4em;
    }

    #{$block}__body {
      width: 100%;
      margin: 0;
      //margin-left: -0.75em;
      //justify-content: space-between;

      & > * {
        margin: 0.5em 0.25em;
      }

      .edit-menu {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &__avatar {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1.4em;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -0.5em -0.25em;

    & > * {
      margin: 0.5em 0.25em;
    }

    @media screen and (min-width: 25em) {
      margin: -0.5em -0.55em;

      & > * {
        margin: 0.5em 0.55em;
      }
    }

    .edit-menu {
      width: auto;
      margin-left: 0.9em;

      @media screen and (min-width: 25em) {
        margin-left: 0.9em;
      }
    }

    .ai-author {
      font-size: 0.875em;

      .underline {
        text-decoration: underline;
      }
    }
  }
}