.simple-popup {
  --accent-body-background: var(--gray-180);
  --scrollbarBG: --accent-body-background;
  --thumbBG: var(--gray-400);

  &:focus {
    outline: 0;
  }

  &__headline {
    @include h1;
    margin-bottom: 1.5rem;
  }

  &__subheadline {
    @include h3;
    text-align: center;
    margin-bottom: 0.6875em;
  }

  &__children {
    // TODO: Why is this here? This should not be here.
    .page-logoEdit {
      flex-direction: column;

      .file-preview {
        margin: 0 0 1em;
      }
    }

    @media screen and (min-width: 46em) {
      .page-logoEdit {
        flex-direction: row;

        .file-preview {
          margin: 0 1em 0 0;
        }
      }
    }
  }

  &__accent-body {
    margin: var(--gap-S) 0;
    border-radius: 0.625em;
    background-color: var(--accent-body-background);
    padding: var(--gap-S);
  }

  &__scrollable-body {
    max-height: 20em;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar-track {
      background-color: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
    }
  }

  @media screen and (min-width: 46em) {
    &__columns {
      display: flex;
    }

    &__column {
      flex: 1;
      margin-right: 1em;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__submit-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    margin-top: 1.5rem;
    /* calc(( margin-from-children + padding-of-wrapper + bottom-offset) * -1) */
    margin-bottom: calc((0.5rem + var(--gap-S) + 1.25rem) * -1);

    > * {
      margin: 0.5rem;
    }
  }

  & .select-counter {
    position: absolute;
    top: -0.8em;
    left: -0.8em;
    padding: 0;
    z-index: 10;
  }

  &--maxHeight {
    .simple-popup__children {
      height: fit-content;
      max-height: calc(92vh - 10em);
      margin-bottom: 2rem;
      overflow-y: auto;
      margin-inline: -1.5em;
      padding-inline: 12px; // 8px scrollguter * 1.5
      scrollbar-gutter: stable;

      // Styling for webkit browsers
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
      }
    }

    .simple-popup__submit-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(-25%);
    }
  }

  &--physicalMouse {
    .simple-popup__children {
      padding-inline: 0;
      padding-inline-start: 12px; // 8px scrollguter * 1.5
    }
  }
}
