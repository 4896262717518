.page-header {
  $block: &;

  display: flex;
  align-items: center;
  background: var(--gradient-secondary);
  padding: 1em;
  padding-bottom: 3.5em;
  position: relative;
  min-height: 11em;
  overflow: hidden;

  @media screen and (min-width: 46em) {
    padding-bottom: 2.5em;
    padding: 2em;
  }

  &--center {
    padding: 1em;
  }

  &--reduced {
    padding: 1em;

    #{$block}__content {
      max-width: 64em;
      padding: 0 1em;
      margin: 0;

      @media screen and (min-width: 76.5em) {
        margin: 0 auto;
        padding: 0 2em;
      }
    }
  }

  &--edit {
    &:hover {
      cursor: pointer;

      #{$block}__edit {
        opacity: 1;
      }
    }
  }

  &--bold {
    #{$block}__headline {
      @include large-title;
    }
  }

  &__title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.056;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__content {
    width: 100%;
    max-width: 54em;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &--wide #{$block}__content {
    max-width: 74em;
  }

  &__avatar {
    flex-basis: 0;

    .avatar {
      width: 4.375em;
      margin-right: var(--gap-S);
    }
  }

  &__inner {
    flex-basis: 0;
    flex-grow: 9999;
  }

  &__edit {
    line-height: 1;
    display: inline-block;
    opacity: 0;
    padding: 0 0.5em;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    color: var(--white);
    transition: var(--hover-transition);

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.2em;
      transition: none;
    }
  }

  &__headline {
    display: block;
    @include copy-large;
    font-weight: 300;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__subline {
    @include h3;
    padding-top: 0.2em;
  }

  &__company {
    padding: 0 1.5em 0 0;
  }

  &__mail {
    text-transform: none;
    // padding: 0 0 0 1.5em;
  }

  &__accounts {
    padding: var(--gap-S) 0;
    flex-grow: 1;
    flex-basis: 0;

    .avatar-stack {
      margin-left: var(--gap-S);
    }
  }
}
