.close-button {
  --button-background: var(--gray-250);
  --button-text: var(--white);

  display: block;
  overflow: hidden;
  z-index: 10;

  line-height: 1;
  width: 3.375em;
  height: 3.375em;
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.16);

  &:focus {
    outline: none;
  }
}