.nav-bubble {
  display: grid;
  place-items: center;
  position: absolute;
  font-size: 0.75rem;
  font-weight: 800;
  font-style: italic;
  color: #ffff;
  min-width: 5ch;
  background: rgba(144, 19, 254, 0.9);
  padding: 0.1em 0.1em;
  border-radius: 99rem;
  right: 0;
  top: -1.25em;

  &:before {
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;
    right: 1em;
    bottom: -1em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'%3E%3Cpath fill='%239013FE' fill-opacity='.9' fill-rule='evenodd' d='M0 0c1 2 3 5 3 10L13 0H0z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 100% 100%;
  }
}
