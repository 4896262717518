.edit-input {
  $block: &;
  --edit-background: var(--gray-180);
  --text-color: var(--white);
  --edit-text-color: var(--gray-750);

  position: relative;
  line-height: 1;
  z-index: 1;

  &--login {
    width: 10em;
    #{$block}__input {
      font-size: 1em;
      padding: 0.5em 1.25rem;
      height: calc(1em + 1rem + 1rem);
    }

    #{$block}__select-wrapper {
      &::after {
        top: 1.05em;
      }
    }
  }

  label {
    display: inline-block;
    @include h2;
    line-height: 1;
    color: var(--edit-text-color);
    margin-bottom: 1rem;
  }

  &__select-wrapper {
    position: relative;

    &::after {
      position: absolute;
      top: 1.7em;
      right: 1em;
      display: block;
      content: '';
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><path fill="%23ffffff80" d="M12.663238,16.6944813 C12.2957425,17.0994487 11.7042795,17.1042235 11.3324915,16.6944813 L3.20134064,7.7332584 C2.83388506,7.32829097 2.98514121,7 3.52683095,7 L20.4698523,7 C21.0170712,7 21.1670988,7.32351619 20.7952704,7.7332584 L12.663238,16.6944813 Z"/></g></svg>');
      background-size: cover;
      z-index: 5;
      width: 1em;
      height: 1em;
      pointer-events: none;
    }
  }

  &__input {
    display: block;
    @include copy-large;
    line-height: 1.5;
    font-weight: normal;
    font-style: unset;
    text-transform: unset;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: calc(1em + 1.25rem + 1.25rem);
    background: var(--edit-background);
    color: var(--text-color);
    border: none;
    border-radius: 3px;
    padding: 0.5rem 1.25rem;
    margin: 0;

    &--normal-font {
      @include copy-normal;
    }

    &--gap {
      margin-bottom: 1em;
    }

    &--simple-headline {
      @include h1;
      text-transform: unset;
      padding: 0.5rem 0.5rem;
      height: calc(1em + 0.5rem + 0.5rem);
      margin-bottom: 0.2em;
      outline-offset: -0.1em !important;
    }

    &--simple-subheadline {
      @include h2;
      text-transform: unset;
      padding: 0.5rem 0.5rem;
      height: calc(1em + 0.5rem + 0.5rem);
    }

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.18em;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  &__link {
    display: inline-block;
    @include copy-small;
    position: absolute;
    bottom: -2.5em;
    left: 1em;

    a {
      color: var(--white-opacity);
      text-decoration: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -0.1em;
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed var(--white-opacity);
    }
  }

  .input-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &--success {
    .input-button {
      background-color: var(--bright-green);
    }
  }
}
