.invite-members {
  $block: &;

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__top {
    width: 100%;
    display: grid;
    grid-template-columns: 1.2em 1fr;
    margin-bottom: 0.75rem;
    align-items: flex-start;

    #{$block}__name {
      margin-bottom: 0;
      grid-column: 1 / 3;
      grid-row: 1/1;
      padding: 0 1.5em;
    }

    #{$block}__icon {
      grid-column: 1/1;
      grid-row: 1;
    }
  }

  &__name {
    text-align: center;
    margin-bottom: 0.75rem;
    font-size: 1.3em;
  }

  &__checkable {
    margin-top: var(--gap-S);
  }
}
