.input-button {
  @include h2;
  width: 15em;
  height: 100%;
  max-height: 4.2em;
  color: var(--white);
  background: var(--bright-pink);
  outline: none;

  &:disabled {
    background: var(--bright-pink-2);
    cursor: not-allowed;
  }

  &:focus {
    outline: 1px dashed var(--white);
    outline-offset: -0.25em;
  }
}
