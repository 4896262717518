.sidebar-teaser {
  cursor: pointer;

  &__body {
    display: flex;
    align-items: flex-start;

    .session-icon {
      margin-right: 0.75rem;
    }

    .typography {
      line-height: 1.5;
    }
  }

  &__decoration {
    width: 100%;
    max-width: 17.5rem;
    display: grid;
    place-items: center;
    margin-top: 0.5rem;
    color: var(--white);

    > * {
      grid-column: 1;
      grid-row: 1;
      max-width: 17.5rem;
    }
  }

  &__large-decoration {
    max-width: 20rem;

    > * {
      max-width: 20rem;
    }
  }

  &__image {
    display: block;
    width: 100%;
    border-radius: 0.625rem;
    max-height: 14.375rem;
    object-fit: cover;
  }

  &__icon {
    width: 3.75rem;
    height: 3.75rem;
    display: grid;
    border-radius: 50%;
    background: var(--bright-pink);

    > .icon {
      margin: auto;
      width: 2rem;
      height: 2rem;
    }
  }
}
