.avatar-stack {
  display: flex;
  flex-direction: row-reverse;

  > * {
    width: 2.5em;
  }

  > * + * {
    margin-right: -0.5em;
    transition: margin-right 0.2s ease-out;
  }
}