.card-column {
  $block: &;

  position: relative;
  width: 17.3125em;
  min-height: 40em;
  background: var(--gray-180);
  border-radius: 0.625em;
  flex-shrink: 0;

  &--add {
    background: transparent;

    #{$block}__head {
      opacity: 0.8;
      border: 1px dashed var(--text-color-secondary);
      background-clip: border-box;
      cursor: pointer;

      &:hover {
        opacity: 1;
        color: var(--text-color);
      }
    }

    #{$block}__button {
      display: none;
    }
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(3.375em + 2px);
    padding: 1em;
    background: var(--gray-250);
    border: 1px solid transparent;
    border-radius: 0.625em;
    margin-bottom: 1em;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: var(--text-color);

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.5em;
    }

    &--line-clamp-3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    &--input {
      @include h3;
      @include copy-normal; // mobile zoom fix
      text-align: center;
      height: calc(3.375rem + 2px);
      margin-bottom: 1rem;

      @media screen and (min-width: 46em) {
        @include h3;
      }
    }

    &--textarea {
      @include h3;
      @include copy-normal; // mobile zoom fix
      text-align: center;

      @media screen and (min-width: 46em) {
        @include h3;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 6;
  }

  &__title,
  &__value {
    @include h3;

    &--ai {
      padding-left: 1em;
      text-align: start;
    }
  }

  .destroy-button {
    visibility: visible;
    opacity: 1;
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    transition: var(--hover-transition);

    &:focus {
      transition: none;
    }
  }

  @media screen and (min-width: 60em) {
    .destroy-button {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__button {
    display: flex;
    justify-content: space-evenly;
    padding: 1em 2em;
  }

  &:hover {
    .destroy-button {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content-box {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  &__content-text {
    margin: 1em 0;
    display: flex;
    align-items: center;
    max-width: 80%;
  }

}
