.edit-textarea {
  --edit-background: var(--gray-180);
  --text-color: var(--white);
  --edit-text-color: var(--gray-750);

  $block: &;
  display: block;
  line-height: 1;
  flex-grow: 9999;

  label {
    display: block;
    @include h2;
    line-height: 1;
    color: var(--edit-text-color);
    margin-bottom: 1rem;
  }

  &__text {
    @include copy-normal;
    line-height: 1.5;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 18rem;
    background: var(--edit-background);
    color: var(--text-color);
    border: none;
    border-radius: 3px;
    resize: none;
    overflow: auto;
    padding: calc(1.25rem - 0.3rem);
    border: 0.3rem solid var(--edit-background);

    @media (min-width: 46em) {
      @include copy-small;
    }

    &--large-font {
      @include copy-large;
    }

    &--normal-font {
      @include copy-normal;
    }

    &--slim {
      height: 12rem;
    }

    &--gap {
      margin-bottom: 1em;
    }

    &--slider {
      width: 100%;
      height: calc(14.3125rem + 2px);
    }

    &--slim-padding {
      padding: 0.4375em;
    }

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.3rem;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  &__predefined-text {
    @include copy-large;

    font-weight: normal;
    font-style: unset;
    text-transform: unset;
    color: var(--text-color);
    padding: 1.25rem;
    display: block;
  }

  &__predefined-text + .input-state > &__text {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    background: var(--edit-background);
    color: var(--text-color);
    border-radius: 3px;
    border: 0.3rem solid var(--edit-background);

    &--focused {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.3rem;
    }

    #{$block}__predefined-text {
      padding: calc(1.25rem - 0.3rem);
      padding-bottom: 0;
    }

    #{$block}__text {
      padding: calc(1.25rem - 0.3rem);
      padding-top: 0.4em;
      overflow: hidden;
      border: 0;

      &:focus {
        outline: none;
      }

      &:focus + #{$block}__outline {
        visibility: visible;
      }
    }
  }

  &__wrapper,
  &__text {
    --scrollbarBG: var(--gray-180);
    --thumbBG: var(--gray-110);

    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }

    &::-webkit-scrollbar-corner {
      background: var(--scrollbarBG);
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 6px;
      border: 4px solid var(--scrollbarBG);
    }
  }
}
