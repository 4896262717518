.l-page {
  $block: &;
  overflow-x: hidden;

  --container-width: calc(100% + var(--nav-width) - 1em);
  --translation: calc(var(--nav-width) - 1em);

  &--reduced {
    --container-width: calc(100% + 5.6em);
    --translation: 5.6em;

    #{$block}__toggle-button {
      display: none;
    }

    #{$block}__second {
      background: var(--background-first);

      @media screen and (min-width: 65em) {
        height: 100vh;
        overflow-x: hidden;
      }
    }
  }

  &--open {
    --translation: 0;

    #{$block}__toggle-button {
      transform: translateX(calc(-100% - 2em));
    }

    #{$block}__first {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }
  }

  @media screen and (min-width: 70.5em) {
    --translation: 0;
    --container-width: 100%;
  }

  &__content {
    min-height: 100vh;
    transition: margin-left 0.3s ease-out;
  }

  &__first {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--background-third);
    z-index: 20;
    pointer-events: none;
    transform: translateX(-5.5em);
    transition: transform 0.2s ease-out;
  }

  &__second {
    background: var(--background-second);
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
      width: 13px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 6px;
      border: 4px solid var(--scrollbarBG);
    }
  }

  @media screen and (min-width: 65em) {
    &__first {
      position: relative;
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }

    &__content {
      display: grid;
      grid-template-columns: 5.5em 1fr;
      margin-left: calc(var(--translation) * -1);
      width: var(--container-width);
    }
  }

  &__toggle {
    position: fixed;
    position: sticky;
    top: 0;
    z-index: 25;
  }

  &__toggle-button {
    position: absolute;
    left: calc(var(--nav-width) + 0em);
    top: 0.5em;
    transition: transform 0.4s ease-out;
    background: transparent;
    border: none;
    display: block;
    text-align: center;
    width: 1.8em;
    height: 1.8em;

    &:after {
      content: '';
      display: block;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -53 384 384"><path fill="%23bebebe" d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"/></svg>');
      background-size: cover;
      background-position: center;
    }

    @media screen and (min-width: 70.5em) {
      display: none;
    }
  }
}
