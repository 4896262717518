.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  opacity: 1;
  transition: opacity 0.1s;

  &--hidden {
    opacity: 0;
  }

  &__medium {
    min-width: 1.2em;
    width: 1.2em;
    min-height: 1.2em;
    height: 1.2em;
  }

  &__large {
    min-width: 1.5em;
    width: 1.5em;
    min-height: 1.5em;
    height: 1.5em;

    + .edit-input {
      margin-left: -1rem;
    }
  }

  @at-root & + &--hidden,
    &--hidden + & {
    margin-left: -1em;
  }
}
