.link-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.875em;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-width: 00rem;
    border: solid 1px var(--highlight);
    border-radius: 0.714em;
    padding: 0.6rem 2rem;
    background-color: transparent;
    color: var(--text-color-secondary);
    text-decoration: none;

    &--active {
      background: var(--highlight);
      color: var(--text-color);
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }
  }
}
