.select-counter {
  --counter-background: var(--bright-green);

  @include h2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  background: var(--counter-background);
  border-radius: 50%;
  font-weight: 500;
  text-align: center;
}
