.company-nav-item {
  $block: &;
  --subtitle-color: var(--white-opacity);
  --text-color: var(--white);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(3.375rem);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0;
  background: none;
  cursor: pointer;

  .company-logo {
    margin-right: 0.75em;
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
  }

  &__body {
    text-align: left;
  }

  &__link {

    &--active {
      background: var(--hover-background);
      color: var(--highlight);

      &:hover {
        color: var(--highlight);
      }
    }

    &--inactive {
      opacity: 0.2;
      background: none;
      color: inherit;

      &:hover {
        background: none;
        color: inherit;
        opacity: 0.2;
      }
    }
  }

  &__title {
    @include h3;
    line-height: 1;
    max-width: 9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    @include h3;
    line-height: 1;
    text-transform: unset;
    margin-top: 0.4em;
    font-style: normal;
    font-weight: normal;
    max-width: 9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--subtitle-color);
  }
}