.avatar {
  $block: &;
  min-width: 2.5em;
  max-width: 4.375em;
  width: 100%;
  border-radius: 50%;
  color: inherit;
  background:  var(--background-second);

  &__square {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  &__copy {
    font-size: 0.875em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-second);
    border: 1px solid var(--highlight);
    color: var(--white);
    border-radius: 50%;
  }

  &__image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;

    &:before {
      font-size: 0.875em;
      line-height: 1;
      background: var(--background-second);
      border: 1px solid var(--highlight);
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 50%;
    }
  }

  &__name {
    @include h5;
    line-height: 1;
    position: absolute;
    bottom: -2.5em;
    left: 50%;
    transform: translate(-50%, 1em);
    white-space: nowrap;
    padding: 0.5em 1em;
    border-radius: 1em;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white);
    background: var(--background-third);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out, transform 0.4s ease-out;
    z-index: 2;
  }

  &:hover,
  &:focus {
    #{$block}__name {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &:focus {
    outline: 1px dashed var(--white-opacity);
    outline-offset: 0.25em;
  }

  &--add {
    opacity: 0.5;

    #{$block}__copy {
      opacity: 0.5;
      background: none;
      border: 1px dashed var(--text-color);
    }
  }
}