.menu-button {
  --text-color: var(--white);
  --text-color-opacity: var(--white-opacity);

  @include h3;
  line-height: 1.25;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  padding:0;
  margin: 0;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: var(--text-color);

  &__icon {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 15em;
    text-align: left;
  }

  &__description {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    color: var(--text-color-opacity);
    line-height: 1.4;
    text-align: left;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--text-color-opacity);
  }

  .icon {
    margin-right: 0.75rem;
    height: 1.2em;
    width: 1.2em;

  }

  &:focus {
    outline: 1px dashed var(--white-opacity);
    outline-offset: 0.5em;
  }
}
