.display-inline-flex {
  display: inline-flex;
}

.margin-auto-h {
  margin-left: auto;
  margin-right: auto;
}

.margin-auto-l {
  margin-left: auto;
}

.margin-v {
  margin-top: var(--margin-v, 1em);
  margin-bottom: var(--margin-v, 1em);
}

.max-width-50 {
  max-width: 50%;
}

.text-center {
  text-align: center;
}

.opaque {
  opacity: 0.5;
}
