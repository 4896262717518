.float-button {
  --button-background: var(--highlight);
  --button-text: var(--white);

  display: block;

  line-height: 1;
  width: 3.375em;
  height: 3.375em;
  appearance: none;
  cursor: pointer;
  transition: var(--hover-transition);

  background-color: transparent;

  .icon {
    font-size: 1.5em;
    transition: var(--hover-transition);
    transform: rotate(0deg);
  }

  &--colored {
    background: var(--button-background);
    color: var(--button-text);
    border-radius: 50%;

    &:hover {
      background: var(--highlight-dark);
    }

    &:focus {
      outline: none;
      background: var(--highlight-dark);
    }
  }

  &--add {
    .icon {
      transform: rotate(45deg);
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 26px;
    margin: auto;
    cursor: pointer;
    position: relative;

    &:before,
    & span,
    &:after {
      width: 80%;
      height: 2.5px;
      display: block;
      background: var(--white);
      border-radius: 2px;
      position: absolute;
      opacity: 1;
    }
    &:before,
    &:after {
      transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
      -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
        background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
      content: '';
    }
    &:before {
      top: 5px;
    }
    & span {
      top: 13px;
    }
    &:after {
      top: 21px;
    }
    // /* Hover */
    // &:hover:before {
    //   top: 7px;
    // }
    // &:hover:after {
    //   top: 23px;
    // }
    /* Click */
    &.cross span {
      opacity: 0;
    }
    &.cross:before,
    &.cross:after {
      top: 50%;
    }
    &.cross:before {
      transform: rotate(45deg);
    }
    &.cross:after {
      transform: rotate(-45deg);
    }
    &:focus {
      outline: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--text-color-opacity);
  }
}
