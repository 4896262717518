$space-size-scale: (
  '0': 0,
  's': 0.5rem,
  'm': 1rem,
  'l': 2rem
);

:root {
  @each $key, $value in $space-size-scale {
    --space-#{$key}: #{$value};
  }
}

.stack-base-xx {
  margin-top: var(--stack-space, 1em);
}

@each $key, $value in $space-size-scale {
  #{'.stack-' + $key + ' > * + *'} {
    @extend .stack-base-xx;
  }

  #{'.stack-' + $key + ' > *'} {
    --stack-space: var(--space-#{$key});
  }
}

/* -------------------------------- */
/* Utility Classes for Self-Spacing */

@each $key, $value in $space-size-scale {
  #{'.my-stack-space-' + $key} {
    --stack-space: var(--space-#{$key});
  }
}

.select-menu--wrapper {
  row-gap: 1.25em;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 1fr auto;
  position: relative;
  width: auto;
  max-width: 44em;

  [state] {
    grid-area: 1/1/2/4;
    display: flex;
    justify-content: center;

    .flash-message {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}

.select-menu {
  --menu-background: var(--gray-250);

  position: relative;

  grid-row: 2/3;
  grid-column: 1/4;

  background: var(--menu-background);
  border-radius: 0.714em;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);

  display: grid;
  grid-template-columns: 1fr max-content 1fr; // add 1fr to the end for briefing

  .select-counter {
    position: absolute;
    top: -0.8em;
    left: -0.8em;
    padding: 0;
    z-index: 10;
  }

  &__buttons {
    grid-area: 2/1/3/4;
    grid-template-columns: subgrid;
    display: grid;
    padding: var(--space-m);
    background: var(--menu-background);
    border-radius: 0.714em;
    margin-top: -1.5rem;
    z-index: 2;
    gap: var(--space-m);
  }

  .destroy-button {
    position: absolute;
    top: -0.8em;
    right: -0.8em;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }
  }

  &--fourColumns {
    grid-template-columns: 1fr max-content 1fr 1fr;

    .select-menu__buttons,
    .select-top {
      grid-column-end: 5;
    }
  }

  &--fiveColumns {
    grid-template-columns: 1fr max-content 1fr 1fr 1fr;

    .select-menu__buttons,
    .select-top {
      grid-column-end: 6;
    }
  }
}
