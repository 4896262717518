.sticky-benchmark-bar {
  position: sticky;
  margin: 5% auto;
  bottom: 10px;
  width: 39em;
  z-index: 99;
  background: var(--gray-250);
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border-radius: .635em;
  gap: 5%;

  .icon {
    position: absolute;
    left: 20px;
    top: 18px;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .text {
    margin-left: 15%;
  }
  .main-button {
    width: 35%;
  }
}