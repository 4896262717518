.section-headline {
  display: flex;
  flex-wrap: wrap;
  padding: 5em 0 1.875em;
  margin: 0;
  line-height: 1;

  &--small {
    padding: 5em 0 0;
  }

  &--single {
    padding: 3em 0 0;
  }

  &--noPadding {
    padding: 0 0 1.875em;
  }

  &--lessPaddingTop {
    padding-top: 2em;
  }
  > h2 {
    @include h2;
    line-height: 1.5;
    margin-right: auto;
  }

  &--big {
    > h2 {
      @include h2;
      line-height: 2;
      font-size: 1.5em;
    }
  }

  &__counter {
    @include h2;
    line-height: 1;
    margin-left: 0.3em;
    flex-grow: 1;
  }

  &__caption {
    display: block;
    width: 100%;
    padding: 0.2em 0.5em 0 0;

    .typography {
      margin: 0;
      line-height: 1.5;
    }
  }

  &__anchor {
    line-height: 1.5;
    padding: 0;

    a {
      color: var(--white-opacity);
      transition: var(--hover-transition);

      &:hover {
        color: var(--white);
      }

      &:focus {
        color: var(--white);
        outline: none;
      }
    }
  }
}