.image-prompt-card {
  --text-color: var(--white);

  $block: &;
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    width: 2em;
    height: 2em;
    padding: 0.4em;
    border-radius: 50%;
    margin: 0;
    color: #fff;
    transition: var(--hover-transition);
  }

  &__title {
    @include h1;
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-word;
    word-break: break-word;
  
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    font-size: 1.125em;
    line-height: 1;
    font-weight: normal;
    font-style: unset;
    text-transform: unset;
    color: var(--white-opacity);
  }
}