.login-form {
  --form-background: var(--gray-250);

  display: flex;
  justify-content: center;
  padding: 2em 0 2em 0;
  width: 100%;
  max-width: 40em;
  color: var(--white);

  form {
    width: 100%;
    padding: 2em;
    background: var(--form-background);
    border-radius: 0.625em;

    @media screen and (min-width: 46em) {
      padding: 3em
    }

    .typography {
      padding-bottom: 2em;
    }

    .l-row-wrapper {
      justify-content: center;

      // if you want to align left on desktop
      // @media screen and (min-width: 46em) {
      //   justify-content: flex-start;
      // }
    }
  }

  &__error {
    background: var(--highlight);
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 3px;
  }
}