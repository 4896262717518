.inspiration-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 0.25rem;
  }

  > h4 {
    @include h4;
    opacity: 0.5;
  }

  /* Align inspiration items to the botttom, when nested in an auto-grid */
  .auto-grid > & {
    align-self: end;
  }
}