.toggle-switch {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  
  &__label {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.875rem;
    height: 1.25rem;
    display: block;
    border: 1px solid var(--white);
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      width: 0.875rem;
      height: 0.875rem;
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
    }

    &--disabled {
      border: 1px solid var(--white-opacity);

      &:after {
        background: var(--white-opacity);
      }
    }
  }

  &:checked + label {
    background: var(--gradient-secondary);
  }

  &:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
}