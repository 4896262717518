.l-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3em 1.5em 2em;
  margin: 0;
  pointer-events: none;
  z-index: 100;

  @media screen and (min-width: 70.5em) {
    padding-left: 23em;

    .float-button[aria-label='Menü'],
    .float-button[aria-label='Menu'] {
      display: none;
    }
  }

  &--absolute {
    position: absolute;
  }

  &--noPadding {
    padding: 0;
  }

  &--center {
    @media screen and (min-width: 70.5em) {
      padding-left: 0;
    }
  }

  &__stack {
    > * + * {
      margin-top: 0.5em;
    }
  }

  .card-wrapper {
    width: 100%;
    max-width: 40em;
  }

  form {
    width: 100%;
    max-width: 44em;
  }

  > * {
    position: relative;
    pointer-events: auto;
  }

  &--disabled {
    pointer-events: auto;
  }

  &--background {
    background: rgba(0, 0, 0, 0.404);
  }

  &--background-dark {
    background: rgba(0, 0, 0, 0.9);
  }

  &--top {
    align-items: flex-start;

    // Fix mobile problem with slect menu
    @media screen and (min-width: 46em) {
      align-items: flex-end;
    }
  }

  &--bottom {
    align-items: flex-end;
  }

  &--hidden {
    display: none;
  }

  &--bottomRight {
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 99;
    margin-bottom: 3em;

    @media screen and (min-width: 46em) {
      margin-bottom: 0;
    }
  }

  &--topRight {
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 10;
  }

  &--twoButtons {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5em;
  }

  &--reduced {
    padding-left: 2em;

    @media screen and (min-width: 46em) {
      padding-left: 5em;
    }
  }
}
