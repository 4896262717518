.project-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;

  .project-type-card {
    border-radius: 15px;
    width: 14rem;
    height: 100%;
    line-height: 30px;
    margin: 0;
    padding: 0;
    padding-bottom: 1rem;

    label {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--gray-300);
    }

    &.selected {
      background-color: var(--gray-300);
    }
  }

  .project-type-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;

    &__title {
      font-weight: bold;
      font-style: italic;
      text-align: center;
    }

    &__description {
      font-size: smaller;
      text-align: center;
    }
  }

  input[type='radio'] {
    float: right;
    margin: 1rem;
    appearance: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    transition: 0.2s all linear;
    position: relative;

    &:checked {
      background-color: var(--bright-green);
      border: 1px solid var(--gray-750);
    }

    &:checked + .project-type-card {
      background-color: var(--gray-750);
    }
  }

  input[type='radio']::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: var(--checkmark-color);
    line-height: 28px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  input[type='radio']:checked::after {
    content: '\2713'; /* Unicode character for a checkmark */
    opacity: 1;
  }

  img {
    width: 7rem;
    height: 7rem;
    margin: 0.7rem;
    margin-top: -1rem;
  }
}
