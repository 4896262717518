$mobile-breakpoint: 70.5em;

:root {
  // Base Colors
  --white: #ffffff;
  --white-opacity: hsla(0, 0%, 100%, 0.5);
  --white-focus: hsla(0, 0%, 100%, 0.1);
  --black: #000000;
  --gray-70: #101014;
  --gray-100: #17141e;
  --gray-110: #181920;
  --gray-180: #262634;
  --gray-250: #31324a;
  --gray-300: #404162;
  --gray-400: #62687b;
  --gray-600: #222536;
  --gray-650: #252439;
  --gray-750: #bebebe;
  --gray-900: #a8a8a8;
  --dark-blue: #304e9d;
  --bright-blue: #5154f1;
  --aqua-marine: #34bfd2;
  --malibu: #59b0ff;
  --bright-pink: #fd2d55;
  --bright-pink-2: #d62145;
  --dark-pink: #e84c88;
  --orchid: #c66cd7;
  --bright-green: #00d241;

  // Text Colors
  --text-color: var(--white);
  --text-color-secondary: var(--gray-750);

  // Gradients
  --gradient-primary: linear-gradient(
    153deg,
    var(--aqua-marine) 0%,
    var(--bright-blue) 35%,
    var(--orchid) 100%
  );
  --gradient-secondary: linear-gradient(
    to right,
    var(--dark-blue) 0%,
    var(--dark-pink) 100%
  );

  // Background Colors
  --background-first: var(--gray-110);
  --background-second: var(--gray-70);
  --background-third: var(--black);

  // Overlays
  --overlay: rgba(0, 0, 0, 0.5);
  --overlay-invert: rgba(255, 255, 255, 0.5);

  // Highlights
  --highlight: var(--bright-pink);
  --highlight-dark: var(--bright-pink-2);

  // Width and Gaps
  --nav-width: 20em;

  --gap-NONE: 1px; // Remember to keep gap-variables in sync with utils/constants/gap-sizes.ts
  --gap-XS: 1.25em; // 20px
  --gap-S: 1.5em; // 24px
  --gap-M: 2em; // 32px
  --gap-L: 2.5em; // 40px
  --gap-XL: 4.5em; // 72px

  @media screen and (min-width: 22.5em) {
    --nav-width: 23.125em;
  }

  // Transitions
  --hover-transition: 0.2s;
}
