.comment-popup {
  $block: &;
  $mobile-breakpoint: 46em;
  --header-color: var(--white);
  --card-background: var(--gray-250);

  //width: 85vw; // needed for popup version
  width: 100%;
  max-width: 54em;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: var(--gap-S);
  padding-bottom: 4em;
  border-radius: 0.625em;
  background: var(--card-background);

  // @media screen and (min-width: $mobile-breakpoint) {
  //   width: 100vw;
  // } // needed for popup version

  > * {
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (min-width: 46em) {
    > * {
      margin-left: calc(var(--gap-S) / 2);
      margin-right: calc(var(--gap-S) / 2);
    }
  }

  .edit-textarea {
    margin-right: 0;
  }

  &__container {
    display: flex;
    flex-grow: 9999;
    flex-direction: column;
    margin: 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    > * {
      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (min-width: 46em) {
      flex-direction: row;

      > * {
        margin-left: calc(var(--gap-S) / 2);
        margin-right: calc(var(--gap-S) / 2);
      }
    }
  }

  &__title {
    @include h3;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__user {
    width: 2.5em;
    margin-bottom: 0.5em;

    @media screen and (min-width: 46em) {
      margin-bottom: 0;
    }
  }

  &__rating-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    > * {
      margin-left: 0;
      margin-right: 0;
      flex-basis: 15rem;
    }

    @media screen and (min-width: 36em) {
      margin-left: calc(var(--gap-S) / -2);
      margin-right: calc(var(--gap-S) / -2);

      > * {
        margin-left: calc(var(--gap-S) / 2);
        margin-right: calc(var(--gap-S) * 2);
      }
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    flex-basis: 10em;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 2em;
    color: var(--header-color);
    opacity: 0.5;
    margin-bottom: 0.625em;

    @media screen and (min-width: 46em) {
      min-height: 2.5em;
    }

    h3 {
      @include h3;
      line-height: 1;
    }

    time {
      @include copy-small;
      line-height: 1.8;
    }
  }

  &__content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-grow: 9999;
  }

  &__comment {
    @include copy-small;
    overflow: auto;
    line-height: 1.5;
    flex-grow: 1;
    margin-right: 0;

    background:
    /* Shadow covers */
    linear-gradient(var(--gray-250) 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), var(--gray-250) 70%) 0 100%,

    /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(255,255,255,0.6), rgba(255,255,255,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(255,255,255,0.6), rgba(255,255,255,0)) 0 100%;
    background-color: var(--gray-250);
    background-repeat: no-repeat;
    background-size: 100% 2.5em, 100% 2.5em, 100% 0.875em, 100% 0.875em;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }

  .main-button, 
  .main-button--secondary {
    position: absolute;
    bottom: -1.8em;
    left: 50%;
    transform: translateX(-50%);
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;

    @media screen and (min-width: 46em) {
      right: -1.4em;
    }
  }
}