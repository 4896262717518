.form {

  &__fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  &--space {
    margin-top: 3.5em;
  }
}
