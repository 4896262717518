.l-content {
  $block: &;
  --scrollbarBG: var(--gray-180);
  --thumbBG: var(--gray-110);
  min-height: 100%;

  &__first {
    background: var(--background-second);
    z-index: 15;
    position: fixed;
    left: 5.5em;
    top: 0;
    bottom: 0;
    width: calc(100vw - 5.5em);
    pointer-events: none;
    transform: translateX(calc(-100% - 5.5em));
    transition: transform 0.5s ease-out;

    .l-page--open & {
      pointer-events: all;
      transform: translateX(0);
    }
  }

  &__second {
    position: relative;
    background: var(--background-first);
    min-width: 0;
    width: 100vw;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
      width: 13px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 6px;
      border: 4px solid var(--scrollbarBG);
    }
  }

  @media screen and (min-width: 65em) {
    display: grid;
    grid-template-columns: calc(var(--nav-width) - 6em) 1fr;

    &__first {
      position: static;
      display: block;
      width: auto;
      left: auto;
      pointer-events: all;
      transform: translateX(0);
    }

    &__second {
      width: auto;
      height: 100vh;

      &:before {
        content: '';
        position: fixed;

        background-color: transparent;
        top: 45px;
        height: 50px;
        width: 25px;
        border-top-left-radius: 0.625em;
        z-index: 99;
        box-shadow: 0 -0.625em 0 0 var(--background-second);
      }

      &--noHeader {
        &:before {
          content: none;
        }
      }
    }
  }
}
