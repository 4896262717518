.circle-chart {
  /* --value: 60; */
  max-width: 3.5rem;
  position: relative;

  svg {
    width: 100%;
    display: block;
  }

  &__stroke {
    stroke-dasharray: 100px;
    stroke-dashoffset: calc((100 - var(--value, 0)) * 1px);
    transform-origin: 300px 300px;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 0.7s ease-in-out;
  }

  &__value {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.625rem;
    color: #fff;
    font-weight: 800;
    opacity: 0.84;
    font-style: italic;
  }
}
