@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,800,800i&display=swap');

html {
  font-size: 100%;
  transition: font-size 0.3s ease-out;

  @media screen and (min-width: 94em) {
    font-size: 112.5%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  background: var(--background-first);
  color: var(--text-color);
  overflow-x: hidden;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

::-webkit-scrollbar {
  height: 0.5rem;
  width:  0.5rem;
}

::-webkit-scrollbar-track {
  background-color: var(--gray-110);
  border: 1px solid var(--gray-180);
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-400);
  border-radius: 0.25rem;
  margin: 0.25rem;
}

