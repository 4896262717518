.main-nav {
  $block: &;

  --icon-background: var(--text-color);
  --icon-color: var(--background-third);
  --icon-active: var(--highlight);

  // Fixes the problem on mobile but enables scrolling
  // position: fixed;
  top: 0;
  min-height: 100vh;
  background: var(--background-third);
  padding: 1em;
  width: 5.5em;
  display: flex;

  @media screen and (min-width: 46em) {
    position: fixed;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin-bottom: var(--gap-S);

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .avatar {
    max-width: 3.5em;
    margin-bottom: 0.5em;
  }

  &__anchor {
    text-decoration: none;
  }

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    opacity: 0.5;
    text-align: center;
    text-decoration: none;
    transition: var(--hover-transition);

    &:hover {
      opacity: 1;
    }

    &:focus {
      opacity: 1;
      outline: none;
      transition: none;
    }

    &--active {
      opacity: 1;

      #{$block}__icon {
        background: var(--icon-active);
        color: var(--icon-background);
      }

      #{$block}__icon::before {
        display: block;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.625em;
    background: var(--icon-background);
    color: var(--icon-color);

    &::before {
      content: '';
      display: none;
      position: absolute;
      top: 0.4375em;
      left: -1em;
      width: 3px;
      height: 1.625em;
      background: var(--icon-active);
    }

    svg {
      font-size: 1.5em;
    }
  }

  &__label {
    @include h5;
    line-height: 1.18;
    margin-top: 0.5em;
  }

  &__avatar {
    margin-bottom: var(--gap-S);

    &--active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1.7375em;
        left: 0;
        width: 3px;
        height: 1.625em;
        background: var(--icon-active);
      }
    }
  }
}
