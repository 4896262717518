.search-form {
  --button-background: var(--highlight);
  --button-text: var(--white);

  width: 100%;
  height: 100%;

  &__fieldset {
    border: 0;
    max-width: 100%;
    width: 100%;
    height: 3.375em;
    display: flex;
    gap: 0.875em;
    flex-direction: row;
    background-color: rgb(37, 36, 57);
    padding: 0;
    border-radius: 3.375em;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  }

  &__input {
    border: none;
    background-color: transparent;
    padding-right: 8px;
    color: white;
    flex-shrink: 1;
    min-width: 0;
    width: 100%;
    outline: none;

    &:last-child {
      padding-right: 2.375em;
    }
  }

  &__input-icon,
  &__close-button {
    border-radius: 3.375em;
    width: 3.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__select {
    border: none;
    background-color: var(--gray-300);
    padding-left: 1.375em;
    border-right: 0.875em solid var(--gray-300);
    color: white;
    outline: none;
    width: auto;
    height: 100%;
    border-radius: 0;
    appearance: none;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__afterWrapper {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      translate: -1.75rem;
      display: block;
      content: '';
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><path fill="%23ffffff80" d="M12.663238,16.6944813 C12.2957425,17.0994487 11.7042795,17.1042235 11.3324915,16.6944813 L3.20134064,7.7332584 C2.83388506,7.32829097 2.98514121,7 3.52683095,7 L20.4698523,7 C21.0170712,7 21.1670988,7.32351619 20.7952704,7.7332584 L12.663238,16.6944813 Z"/></g></svg>');
      background-size: cover;
      z-index: 10;
      width: 1em;
      height: 1em;
      pointer-events: none;
    }
  }

  &__close-button {
    background: var(--button-background);
    color: var(--button-text);
  }

  &__input-icon {
    display: flex;
  }

  &__search-bar {
    .search-form__fieldset {
      background-color: var(--gray-250);
    }
  }
}
