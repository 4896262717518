.l-trello-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: min-content;
  margin: 0;
  margin-top: -4em;
  padding: 4em 2em 2em 0;

  @media screen and (min-width: 46em) {
    padding: 4em 2em 2em 2em;
    margin-top: -4em;
  }

  & > * {
    margin: 0 calc(calc(3em / 2) / 2);
  }

  & > .card-column {
    margin-top: -2em;
  }

  &__page-content-wrapper {
    --scrollbarBG: var(--gray-180);
    --thumbBG: var(--gray-110);

    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    overflow: auto;
    position: relative;
    // height: 100vh;

    &::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }

    &::-webkit-scrollbar-corner {
      background: var(--scrollbarBG);
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 6px;
      border: 4px solid var(--scrollbarBG);
    }

    & > .page-header {
      position: sticky;
      left: 0;
    }
  }
}
