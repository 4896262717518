.edit-button {
  display: block;
  position: relative;
  overflow: visible;

  @include h3;
  line-height: 1;
  height: auto;
  padding: 0.4em;
  margin-left: -0.4em;
  border-radius: 50%;
  background: transparent;
  color: inherit;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: var(--hover-transition);

  &:hover {
    background: var(--white-focus);
  }

  &:focus {
    outline: none;
    background: var(--white-focus);
  }

  @at-root & + .edit-menu__menu {
    .popup-menu {
      margin-top: .75em;
      /* margin-left: .45em; */

      @media (min-width: 46em) {
        margin-top: 0.75em;
        /* margin-left: .9em; */
      }
    }
  }
}
