.hashtags-list {
  display: block;
  max-height: 3em;
  overflow: hidden;
  padding: 0;
  margin: 0;

  &__item {
    display: inline-block;
    margin-right: 1em;
    color: var(--white-opacity);
    white-space: nowrap;
    text-overflow: ellipsis;

    &::before {
      content: '#';
    }
  }
}
