.raised-hand {
  $block: &;

  position: absolute;
  top: -1em;
  left: -1em;
  padding: 0;
  z-index: 5;

  > img {
    height: 2.75rem;
    width: 2.75rem;
  }

  &__label {
    display: block;
    position: absolute;
    top: 1.3rem;
    left: 0.8rem;
    width: 1.5rem;
    font-size: 0.8rem;
    color: var(--black);
    text-align: center;
  }

  &__name {
    @include h5;
    line-height: 1;
    position: absolute;
    bottom: -2.5em;
    left: 50%;
    transform: translate(-50%, 1em);
    white-space: nowrap;
    padding: 0.5em 1em;
    border-radius: 1em;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white);
    background: var(--background-third);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out, transform 0.4s ease-out;
    z-index: 2;
  }

  &:hover {
    #{$block}__name {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
