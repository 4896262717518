.tag-list {
  --scrollbarBG: var(--gray-110);
  --thumbBG: var(--gray-180);

  $mobile-breakpoint: 46em;
  $block: &;

  max-width: 100vw;
  height: 5.5em;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  margin: 0;
  padding: 1em 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(min-content, 13em);
  grid-gap: 0.6em;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  @media screen and (min-width: $mobile-breakpoint) {
    height: auto;
    grid-gap: var(--gap-S);
  }

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 4px solid var(--scrollbarBG);
  }

  &__list-item {
    position: relative;

    .destroy-button {
      position: absolute;
      top: -0.5em;
      right: -0.5em;
      visibility: visible;
      opacity: 1;
      transition: all 0.2s;
      z-index: 10;
    }

    &:hover {
      .destroy-button {
        visibility: visible;
        opacity: 1;
        transition: none;
      }
    }

    @media screen and (min-width: 60em) {
      .destroy-button {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &__select-wrapper {
    position: relative;

    &::after {
      position: absolute;
      top: 1.2em;
      right: 1em;
      display: block;
      content: '';
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><path fill="%23ffffff80" d="M12.663238,16.6944813 C12.2957425,17.0994487 11.7042795,17.1042235 11.3324915,16.6944813 L3.20134064,7.7332584 C2.83388506,7.32829097 2.98514121,7 3.52683095,7 L20.4698523,7 C21.0170712,7 21.1670988,7.32351619 20.7952704,7.7332584 L12.663238,16.6944813 Z"/></g></svg>');
      background-size: cover;
      z-index: 10;
      width: 1em;
      height: 1em;
      pointer-events: none;
    }
  }

  &__tag {
    flex-grow: 1;
    @include copy-normal;
    line-height: 1.714;
    display: block;
    position: relative;
    width: 100%;
    min-width: 10em;
    //max-width: 13.571em;
    height: calc(1.714em + 1.5em + 2px);
    padding: 0.5em;
    border-radius: 0.714em;
    background: var(--gray-250);
    color: var(--text-color-secondary);
    text-align: center;
    border: 1px solid transparent;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    z-index: 10;

    .icon {
      vertical-align: -0.143em;
    }

    &:focus {
      outline: none;
      color: var(--text-color);
    }

    // Firefox Outline Fix
    &::-moz-focus-inner {
      border: 0;
    }

    @media screen and (min-width: $mobile-breakpoint) {
      @include copy-small;
      padding: 1em;
      height: calc(1.714em + 2em + 2px);

      &:focus {
        outline: 1px dashed var(--white-opacity);
        outline-offset: -0.5em;
      }
    }

    &--add {
      opacity: 0.8;
      border: 1px dashed var(--text-color-secondary);
      background-clip: border-box;
      cursor: pointer;
      transition: var(--hover-transition);

      &:hover {
        opacity: 1;
        color: var(--text-color);
      }

      &:focus {
        transition: none;
        outline: none;
        opacity: 1;
        color: var(--text-color);
      }
    }

    &--input {
      min-width: 10em;
    }

    &--select {
      text-align: left;
      cursor: pointer;
      padding-right: 3.5em;

      @media screen and (min-width: $mobile-breakpoint) {
        padding-right: 1.5em;
      }
    }
  }
}
