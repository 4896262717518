.suggested-search {
  position: absolute;
  top: 90%;
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  padding: 1em 0.5em;
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-decoration: none;
  z-index: -1;

  &__item {
    @include copy-small;
    color: var(--gray-600);
  }

  &__button {
    display: block;
    width: 100%;
    height: calc(100% + 2px);
    padding: 0.3em 0.5em;
    text-align: left;
    appearance: none;
    -moz-appearance: none;
    background: none;
    outline: none;
    border: 1px solid transparent;

    &:focus {
      border: 1px dashed var(--gray-600);
    }

    &--active {
      color: var(--bright-pink);
    }
  }
}
