.phone-thumbnail {
  width: 4.625rem;
  height: 4.625rem;
  border-radius: 0.625rem;
  background: var(--white);
  overflow: hidden;
  position: relative;

  > img {
    position: absolute;
    left: 0.9375rem;
    top: 0.625rem;
    width: 2.75rem;
    height: auto;
    border-radius: 0.5rem 0.5rem 0 0;
    border-width: 2px;
    border-style: solid;
    border-color: var(--gray-600);
    min-height: 4.125rem;
    object-fit: cover;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  &:after {
    content: "";
    position: absolute;
    left: 1.375rem;
    top: 0.625rem;
    width: 1.75rem;
    height: 0.25rem;
    background: var(--gray-600);
    border-radius: 0 0 2px 2px;
  }
}

.phone-thumbnail-empty {
  width: 4.625rem;
  height: 4.625rem;
  border-radius: 0.625rem;
  background: var(--white);
  overflow: hidden;
  position: relative;
}
