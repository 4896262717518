html.fslightbox-open {
  overflow: initial;
}

.fslightbox-slide-btn-container .fslightbox-slide-btn {
  background: var(--highlight);
  border-radius: 50%;
  padding: 0.5em;
  margin: 0 1em;

  svg {
    display: none;
  }
}

.fslightbox-slide-btn-next-container .fslightbox-slide-btn {
  &:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="chevron-right" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><polygon id="Path" fill="%23fff" fill-rule="nonzero" points="9.098 2 7.001 4.356 12.32 10.333 13.747 12 12.319 13.667 7 19.643 9.098 22 18 12"/></g></svg>');
    background-size: cover;
    background-position: center;
  }
}

.fslightbox-slide-btn-previous-container .fslightbox-slide-btn {
  &:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="chevron-left" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><polygon id="Path" fill="%23fff" fill-rule="nonzero" points="14.902 2 16.999 4.356 11.68 10.333 10.253 12 11.681 13.667 17 19.643 14.902 22 6 12"/></g></svg>');
    background-size: cover;
    background-position: center;
  }
}

.fslightbox-toolbar {
  right: 1em !important;
  top: 1em !important;
}

.fslightbox-toolbar-button {
  background: var(--highlight);
  border-radius: 50%;
  margin: 0 0.5em;
  width: 2em !important;
  height: 2em !important;

  &:first-child {
    display: none;
  }

  &:last-child {
    svg {
      display: none;
    }

    &:after {
      content: '';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1em;
      height: 1em;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="cross" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><polygon id="Path" fill="%23fff" points="22 4 14 12 22 20 20 22 12 14 4 22 2 20 10 12 2 4 4 2 12 10 20 2"/></g></svg>');
      background-size: cover;
      background-position: center;
    }
  }
}

.fslightbox-slide-number-container {
  @include h2;
}
