.login-screen {
  --form-background: var(--gray-250);

  padding: 4em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white-opacity);

  @media screen and (min-width: 46em) {
    padding: 4em;
  }

  a {
    color: var(--white-opacity);
    text-decoration: none;

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: 0.3em;
    }
  }
}