@mixin arrows($items-per-row) {
  $block: &;

  $thickness: 0.1rem;
  $radius: 2em;
  $grid-gap: 4.125em;
  $screen-margin-top: 4em;
  $screen-padding-top: 4em;
  $gap-between-arrow-and-screen: 1em;
  $head-size: #{$thickness * 5};
  $color: var(--bright-pink);

  $vertical-gap: $grid-gap + $screen-margin-top + $screen-padding-top;
  $horizontal-gap: $grid-gap;

  &__arrow {
    display: none;
  }

  @if $items-per-row == 1 {
    &:not(:last-child) {
      #{$block}__arrow {
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - #{calc($thickness / 2)});
        width: $thickness;
        height: $vertical-gap;
        background-color: #{$color};
      }
    }

    &:nth-last-child(2) {
      #{$block}__arrow {
        height: calc(
          #{$vertical-gap} - #{$head-size} - #{$gap-between-arrow-and-screen}
        );

        &-head {
          display: block;
          z-index: 1;
          position: absolute;
          top: 100%;
          left: calc(50% - #{$head-size});
          width: 0;
          height: 0;
          border: $head-size solid transparent;
          border-top: $head-size solid #{$color};
        }
      }
    }
  } @else {
    &:nth-child(#{$items-per-row - 1}n):not(:last-child),
    &:not(:last-child) {
      #{$block}__arrow {
        display: block;
        position: absolute;
        top: calc(
          50% + #{calc($screen-padding-top / 2)} - #{calc($thickness / 2)}
        );
        left: 100%;
        width: $horizontal-gap;
        height: $thickness;
        background-color: #{$color};

        &--small {
          top: calc(50% + #{$screen-padding-top} - #{calc($thickness / 2)});
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &:not(:nth-child(#{$items-per-row - 1}n)):nth-last-child(2) {
      #{$block}__arrow-head {
        display: block;
        z-index: 1;
        position: absolute;
        top: 130%;
        left: calc(50% - #{$head-size});
        width: 0;
        height: 0;
        border: $head-size solid transparent;
        border-top: $head-size solid #{$color};
      }
    }

    &:not(:nth-child(#{$items-per-row}n)):nth-last-child(2) {
      #{$block}__arrow {
        width: calc(
          #{$horizontal-gap} - #{$head-size} - #{$gap-between-arrow-and-screen}
        );

        &-head {
          top: calc(50% - #{$head-size});
          left: calc(100%);
          border: $head-size solid transparent;
          border-left: $head-size solid #{$color};
        }
      }
    }

    &:nth-child(#{$items-per-row - 1}n):not(:last-child) {
      &:nth-last-child(2) {
        #{$block}__arrow {
          top: calc(
            50% + #{calc($screen-padding-top / 2)} - #{calc($thickness / 2)}
          );
          height: $thickness;

          &--small {
            top: calc(50% + #{$screen-padding-top} - #{calc($thickness / 2)});
          }
        }
      }
    }

    &:nth-child(#{$items-per-row}n):not(:last-child) {
      &:nth-last-child(2) {
        #{$block}__arrow {
          top: calc(100% + (#{$head-size} + #{$gap-between-arrow-and-screen}));
          height: calc(
            #{$vertical-gap} -
              (#{$head-size} + #{$gap-between-arrow-and-screen}) *
              2
          );

          &::before {
            top: calc(#{-$head-size} - #{$gap-between-arrow-and-screen} - 1px);
            height: calc(
              50% + (#{$head-size} + #{$gap-between-arrow-and-screen})
            );
          }
        }
      }

      #{$block}__arrow {
        display: block;
        position: absolute;
        top: 100%;
        left: unset;
        right: calc(50% - #{$thickness});
        width: calc(
          100% *
            #{$items-per-row -
            1} +
            #{$horizontal-gap} *
            #{$items-per-row -
            1} +
            #{$thickness *
            2}
        );
        height: #{$vertical-gap};
        background-color: transparent;

        &-head {
          left: calc(#{-$head-size} + #{calc($thickness / 2)});
          top: 130%;

          &--small {
            top: 100%;
          }
        }

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 50%;
          height: 50%;
          border: #{$thickness} solid #{$color};
        }

        &::before {
          box-sizing: content-box;
          right: 0;
          top: -1px;
          border-left: none;
          border-top: none;
          border-bottom-right-radius: #{$radius};
        }

        &::after {
          left: 0;
          bottom: 0;
          top: 50%;
          height: 80%;
          border-right: none;
          border-bottom: none;
          border-top-left-radius: #{$radius};
        }

        &--small {
          &::after {
            height: 50%;
          }
        }
      }
    }
  }
}

.l-user-flow-item {
  position: relative;
  padding-top: 8.5em;
  margin-top: 4em;

  &--small {
    margin-top: 0;
  }

  @include arrows($items-per-row: 1);

  @media (min-width: 1152px) {
    @include arrows($items-per-row: 2);
  }

  @media (min-width: 1063px) and (max-width: 1127px) {
    @include arrows($items-per-row: 2);
  }

  @media (min-width: 1128px) {
    @include arrows($items-per-row: 1);
  }

  @media (min-width: 1440px) {
    @include arrows($items-per-row: 3);
  }

  @media (min-width: 1504px) and (max-width: 1620px) {
    @include arrows($items-per-row: 2);
  }

  &__headline {
    font-weight: 800;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: var(--background-first);
  }

  input {
    @include h2;
    text-transform: none;
    height: 1.5em;
    text-align: center;
  }

  .destroy-button {
    position: absolute;
    top: 3.5em;
    right: -1.2em;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s;
    z-index: 10;

    @media screen and (min-width: 60em) {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:hover {
    .destroy-button {
      visibility: visible;
      opacity: 1;
    }
  }
  .destroy-button {
    top: 8em;
  }
}
