.or-seperator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0.5rem 0;
  text-transform: uppercase;
  align-self: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 1px solid #ccc;
    margin: 0 1rem;
  }

  &--small {
    width: 50%;
  }
}
