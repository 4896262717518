.share-popup {
  // TODO: Force Scroll if height to small (Max Height?)
  --text-color: var(--gray-750);
  --card-background: var(--gray-250);
  --edit-background: var(--gray-180);
  --body-background: var(--gray-180);
  --scrollbarBG: var(--gray-400);
  --thumbBG: var(--gray-180);
  --border-radius: 0.625em;
  --tab-underline-width: 0.375em;

  $block: &;
  width: 100vw;
  max-width: 23.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--gap-S);
  padding-bottom: 2em;
  border-radius: var(--border-radius);
  background: var(--card-background);
  margin-bottom: 1.8em; // Button
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.16);
  z-index: 200;

  @media screen and (min-width: 46em) {
    max-width: 42.6875em;
  }

  &__header {
    background: var(--gradient-secondary);
    margin: calc(-1 * var(--gap-S));
    margin-bottom: var(--gap-NONE);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    padding: var(--gap-S);
    padding-bottom: 0;
  }

  &__headline {
    @include h1;
    line-height: 1;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__body {
    min-height: var(--min-height, 0);
    max-height: 80vh;
    overflow: auto;
    padding: 0.5em 0;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &--centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--evenly {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
    }

    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border: 3px solid var(--scrollbarBG);
    }

    .l-row-wrapper--separator {
      margin-bottom: 0.5em;
    }
  }

  &__tabs {
    display: flex;
    margin-bottom: 0;

    > * + * {
      margin-left: 2em;
    }
  }

  &__tab-link {
    background-color: transparent;
    color: white;
    margin-bottom: calc(-1 * var(--tab-underline-width));
    border: 0 solid transparent;
    border-bottom-width: var(--tab-underline-width);
    padding: 0 0.5em;

    &--active {
      border-bottom-color: var(--highlight);
    }
  }

  &__description {
    @include h2;
    color: var(--text-color);
    padding: 0.5em 0;

    &--centered {
      text-align: center;
    }
  }

  &__icon {
    img {
      margin: 0 auto;
      max-width: 7em;
    }
  }

  &__info {
    color: var(--text-color);
    margin-top: 0.5m;
    margin-bottom: 0.5em;
    font-style: italic;
    font-weight: 800;

    &--space-before {
      margin-top: 3em;
    }
  }

  &__warning {
    color: var(--highlight);
    font-size: 0.8em;
    font-style: italic;
    font-weight: 800;
  }

  .edit-input__input {
    font-size: 1em;
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.6em;
  }

  // "Copy link" button
  .input-button {
    width: 8em;
  }

  .main-button,
  .main-button--secondary {
    --text-color: var(--white);
    position: absolute;
    bottom: -1.8em;
    left: 50%;
    transform: translateX(-50%);
  }
}
