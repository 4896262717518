.destroy-button, .icon-button {
  --button-background: var(--highlight);
  --button-text: var(--white);

  display: block;
  overflow: hidden;
  z-index: 5;

  @include copy-small;
  line-height: 1;
  width: 2em;
  height: 2em;
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
