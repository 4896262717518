.loading {
  cursor: wait;
  display: flex;
  align-items: center;
  justify-content: center;

  // If you want inline styles
  // &--inline {
  // }

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 3em;
    overflow: visible;
  }

  &__path {
    stroke-dasharray: 0.6 0.4;
    animation: loading-stroke 1.5s linear infinite reverse;
    stroke: var(--text-color-secondary);
  }

  &__text {
    &--background {
      padding: 1em;
      margin: 0 1em;
      border-radius: 0.625em;
      height: 6em;
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

@keyframes loading-stroke {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1;
  }
}
