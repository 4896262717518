.l-column-wrapper {
  $wrapper-breakpoint: 46em;

  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto!important;

  @media screen and (min-width: $wrapper-breakpoint) {
    flex-wrap: nowrap;
  }

  & > * {
    margin: var(--column-gap) 0 0;
    transform: translateY(calc(var(--column-gap) * -1));

    &:last-child {
      margin-bottom: calc(var(--column-gap) * -1);
    }
  }

  &--noTranslate {
    & > * {
      &:first-child {
        transform: none;
        margin-bottom: var(--column-gap);
      }
    }
  }

  &--list {
    .section-headline {
      padding-top: 0.7em;
    }
  
    div > .section-headline {
      padding-top: 5em;
    }
  }

  #commentTop {
    margin: 0;
  }

  .card-slider {
    &:first-child {
      .section-headline {
        padding-top: 0;
      }
    }
  }
}