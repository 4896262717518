.icon-counter {
  @include copy-small;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  padding: 0;
  // Maybe We have to update Focus States for these Items here.
  // But without changing padding + margin
  // padding: 0.4em;
  // margin: 0 -0.4em;
  border-radius: 5px;
  background: none;
  color: var(--text-color);
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: var(--hover-transition);

  svg.icon {
    margin-right: 0.5em;
    font-size: 1em;
    color: inherit;
  }

  &--clickable {
    // height: 1em;
    cursor: pointer;

    &:hover {
      background: var(--white-focus);
      box-shadow: 0 0 0 0.4em var(--white-focus);
    }

    &:focus {
      outline: none;
      background: var(--white-focus);
      box-shadow: 0 0 0 0.4em var(--white-focus);
    }
  }
}