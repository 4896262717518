.l-file-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 17.5em));
  grid-auto-rows: 1fr;
  gap: 0.7em;

  &--screen {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 13.875em));
    gap: 4.125em;
    margin-top: 0;
  }

  &--centered {
    justify-content: center;
  }

  &--no-margin {
    margin-top: 0;
  }
}
