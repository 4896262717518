.l-assistant-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  margin-top: var(--gap-M);

  > *:nth-child(3),
  > *:nth-child(4) {
    grid-column: span 2;
  }

  @media screen and (min-width: 75em) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, minmax(23.75rem, auto));
    margin-top: var(--gap-XL);

    > *:nth-child(3) {
      grid-column: 1 / span 2;
    }

    > *:nth-child(4) {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;
    }
  }
}