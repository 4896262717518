.briefing-element {
  $block: &;
  --card-background-primary: var(--gradient-primary);
  --card-background-secondary: var(--gray-250);

  position: relative;
  margin-bottom: 0.625em;

  &--with-top {
    padding-top: 1em; 

    @media screen and (min-width: 56em) {
      padding-top: 7em;
    }
  }

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  .card-header {
    padding-bottom: 0.5em;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--gap-S);
    border-radius: 0.625em;
    background: var(--card-background-secondary);
    width: 100%;
    max-width: 56em;
  }

  &__question {
    word-wrap: break-word;
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 0.625em;
    transition: box-shadow 0.2s ease-out;

    &:hover {
      box-shadow: 0 0 0 0.375em var(--white-opacity) inset;
    }

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.5em;
    }
  }
 
  &--gradient {
    #{$block}__body {
      background: var(--card-background-primary);
    }
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;
  }

  @at-root .card-slider__item > &--with-top {
    padding-top: 0;

    @media (min-width: 56em) {
      padding-top: 6em;
    }
  }

  @at-root .card-slider {
    .card-slider__item {
      #{$block}__body {
        overflow: hidden;
        height: calc(90em - 100vw * 3);

        @media (min-width: 360px) {
          height: calc(52em - 100vw * 1.25);
        }

        @media (min-width: 470px) {
          height: calc(30em - 100vw * 0.5);
        }

        @media (min-width: 530px) {
          height: 15em
        }
      }
    }
  }
}