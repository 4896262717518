@mixin large-title {
  font-size: 2.125em;
  line-height: 1;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-XL {
  @include large-title;
}

@mixin extralarge-title {
  font-size: 2.925em;
  line-height: 1;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-XXL {
  @include extralarge-title;
}

@mixin h1 {
  font-size: 1.625em;
  line-height: 1.25;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-L {
  @include h1;
}

@mixin h2 {
  font-size: 1em;
  line-height: 1.5;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-M {
  @include h2;
}

@mixin h3 {
  font-size: 0.875em;
  line-height: 1.5;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-S {
  @include h3;
}

@mixin h4 {
  font-size: 0.75em;
  line-height: 1.5;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-XS {
  @include h4;
}

@mixin h5 {
  font-size: 0.6875em;
  line-height: 1.5;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}
.headline-XXS {
  @include h5;
}


@mixin copy-normal {
  font-size: 1em;
}

@mixin copy-tiny {
  font-size: 0.625em;
}

@mixin copy-tiny-thin {
  font-size: 0.625em;
  font-weight: 300;
}

@mixin copy-smaller {
  font-size: 0.75em;
  line-height: 1.6;
}

@mixin copy-small {
  font-size: 0.875em;
  line-height: 1.857;
}

@mixin copy-small-thin {
  font-size: 0.875em;
  line-height: 1.857;
  font-weight: 300;
}

@mixin copy-medium {
  font-size: 1.5em;
}

@mixin copy-large {
  font-size: 1.625em;
}

.typography {
  flex-grow: 1;
  margin-bottom: 1em;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;

  &--breaks {
    white-space: pre-wrap;
  }

  &--nomargin {
    margin-bottom: 0;
  }

  &--tiny {
    @include copy-tiny;
  }

  &--small {
    @include copy-small;
  }

  &--smaller {
    @include copy-smaller;
  }

  &--medium {
    @include copy-medium;
  }

  &--large {
    @include copy-large;
  }

  a {
    color: var(--malibu);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

em {
  font-weight: normal;
}
