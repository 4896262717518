.grid-card {
  $block: &;

  display: flex;
  flex-direction: column;
  padding: 1em 0.5em;
  border-radius: 0.625em;
  background: var(--card-background, var(--gray-250));

  p {
    max-width: 50ch;
  }

  &__footer {
    margin-top: auto;
    padding-top: 1rem;
  }

  &--gradient {
    --card-background: var(--gradient-primary);
  }

  @media screen and (min-width: 46em) {
    padding: var(--gap-S);
  }

  // Breakpoint taken from Assistant-Grid
  @media screen and (min-width: 75em) {
    &__phone {
      transform: translateY(-3rem);
    }
  }
}