@import '../styles';

.styleguide {
  padding: 2em;
  > h1 {
    @include h1;
    margin-bottom: 1em;
  }

  > h2 {
    @include h2;
    margin-bottom: 1.5em;
  }

  > h3 {
    @include h3;
  }

  > a {
    @include h4;
    display: inline-block;
  }

  hr {
    margin: 2em 0;
    border: none;
    border-top: 1px solid var(--highlight);
  }
}
