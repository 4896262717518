.comment-card {
  $block: &;
  --header-color: var(--white);
  --card-background: var(--gray-250);

  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1em 0.5em;
  border-radius: 0.625em;
  background: var(--card-background);

  @media screen and (min-width: 46em) {
    padding: var(--gap-S);
  }

  &--menuVisible {
    z-index: 1;
  }

  > * {
    margin-left: calc(var(--gap-S) / 2);
    margin-right: calc(var(--gap-S) / 2);
  }

  &__user {
    width: 2.5em;
    margin-left: 0;
  }

  &__rating-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gap-S) / -2);
    margin-right: calc(var(--gap-S) / -2);
    width: 100%;

    > * {
      margin-left: calc(var(--gap-S) / 2);
      margin-right: calc(var(--gap-S) * 2);
      flex-basis: 15rem;
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    margin-top: 0.3em;
    flex-basis: 10em;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--header-color);
    opacity: 0.5;
    margin-bottom: 0.625em;

    h3 {
      @include h3;
      line-height: 1;
    }

    time {
      @include copy-small;
      line-height: 1.8;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 9999;
  }

  .typography {
    overflow: auto;
    line-height: 1.5;
    flex-grow: 1;
    margin-right: 0;
    margin-bottom: 0;
  }

  .icon-counter {
    margin-top: 1.25em;
    align-self: flex-start;
  }

  .edit-button {
    align-self: flex-start;
    flex-shrink: 0;
  }
}