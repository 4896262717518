.move-buttons {
  position: absolute;
  display: flex;
  bottom: -1.1em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;

  .move-buttons {
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
  }

  &--multiple {
    width: 50%;
    justify-content: space-between;
  }
}

.move-button {
  --button-background: var(--bright-blue);
  --button-text: var(--white);

  display: block;
  overflow: hidden;
  z-index: 10;

  @include copy-small;
  line-height: 1;
  width: 2.5em;
  height: 2.5em;
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 5px var(--white);
    outline: none;
  }
}
