.card-header {
  --text-color: var(--white);

  $block: &;
  display: flex;
  padding-right: 2em;

  &--edit {
    #{$block}__edit-button {
      display: flex;
      position: absolute;
      top: 1em;
      right: 1em;
      justify-content: center;
      align-items: center;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      width: 2em;
      height: 2em;
      padding: 0.4em;
      border-radius: 50%;
      margin: 0;
      color: #fff;
      transition: var(--hover-transition);

      &:hover {
        background: var(--white-focus);
      }

      &:focus {
        outline: none;
        background: var(--white-focus);
      }
    }

    #{$block}__title {
      padding-right: 3em;
    }

    #{$block}__subtitle {
      padding-right: 3em;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 9999;
  }

  &__title {
    @include h1;
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-word;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    &--simple {
      font-size: 1.125em;
      line-height: 1;
      font-weight: normal;
      font-style: unset;
      text-transform: unset;
      color: var(--white-opacity);
    }

    &--line-height-auto {
      line-height: normal;
    }

    &--single {
      font-size: 1em;
      line-height: 1.3;
    }
  }

  &__subtitle {
    @include h2;
    //line-height: 1; // looks a little weird when there's more than one line
    text-transform: unset;
    margin-top: 0.1em;
  }

  .company-logo {
    align-self: center;
    margin-right: var(--gap-S);
  }
}
