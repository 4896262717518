.session {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  min-height: Min(50rem, 100vh);
  background: var(--gray-110);
  overflow: auto;
  padding: 3.875rem 6.125rem 2rem;

  &--min-height-zero {
    min-height: Min(0rem, 100vh);
  }

  &--no-padding {
    padding: 0;
  }

  &__fixed {
    position: absolute;
    position: fixed;
    top: 2.5rem;
    right: 0;
    right: 23rem;
    z-index: 80;
  }

  &__fixed-bottom {
    position: fixed;
    bottom: 1rem;
    left: 6.5rem;
    right: 23.1875rem;
    padding: 1rem 5.625rem 1rem 5.5rem;
    background: var(--gray-250);
    z-index: 2;
    border-radius: 1rem;
  }

  &--extra-bottom-space:after {
    content: '';
    display: block;
    height: 7rem;
    flex-shrink: 0;
  }

  &__top {
    max-width: 46.25rem;

    .session-icon {
      margin-bottom: 0.5rem;
      margin-left: -1rem;
    }

    .avatar {
      margin-bottom: 0.5rem;
      margin-left: -1rem;
    }
  }

  &__bottom {
    width: 100%;
    max-width: 40.625rem;
    align-self: flex-end;
    margin-top: 2rem;
  }

  &__actions {
    margin-top: 1rem;
  }
}
