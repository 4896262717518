.l-row-wrapper {
  $wrapper-breakpoint: 46em;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: calc(var(--row-gap) * -1) calc(var(--row-gap) * -0.5) 0;

  @media screen and (min-width: $wrapper-breakpoint) {
    flex-wrap: nowrap;
  }

  & > * {
    margin: var(--row-gap) calc(var(--row-gap) * 0.5) 0;
    // transform: translate(calc(calc(var(--row-gap) * 0.5) * -1), calc(calc(var(--row-gap) * 0.5) * -1));
  }

  &--alignRight {
    justify-content: flex-end;

    & > * {
      // I don't know, why we needed this transform. Right now it breaks the layout at
      // Some Places. So I'll remove this.
      // transform: translate(calc(calc(var(--row-gap) * 0.5) * 1), calc(calc(var(--row-gap) * 0.5) * -1));
    }
  }

  &--center {
    justify-content: center;
  }

  &--spaceBetween {
    justify-content: space-between;
  }

  &--separator {
    border-bottom: 2px solid var(--gray-180);
  }
}
