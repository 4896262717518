.briefing-detail-row {
  display: flex;
  gap: var(--column-gap);

  > * {
    flex-basis: 50%;
  }

  &--one-third {
    > :first-child {
      flex-basis: 66%;
    }

    > :last-child {
      flex-grow: 1;
    }
  }

  @media (max-width: 46em) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}
