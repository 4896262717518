.search-project-input {
  background: var(--background-first);
  border: none;
  border-bottom: 1px solid var(--highlight);
  color: var(--white);
  padding: 0 0.5em;
  padding-bottom: 1em;

  &:focus {
    outline: none;
  }
}
.project-list {
  --item-background: var(--gray-250);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 18.6875rem));
  grid-auto-rows: minmax(9.9375em, max-content);
  gap: 0.6875rem;
}

.card-wrapper + .project-list {
  margin-top: 2rem;
}