.project-card-small {
  $block: &;
  $project-card-layout-switch: 46em;

  --text-color: var(--white);
  --card-background-primary: var(--gray-250);

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: $project-card-layout-switch) {
    --image-height: 25.25em;
    flex-direction: row-reverse;
    max-width: 18.6875rem;
  }

  &__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    width: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
  }

  &__left-footer {
    .icon-counter {
      margin-right: 0.5em;

      .icon {
        margin-right: 0.2em;
        margin-left: -2px;
      }
    }
  }

  &__right-footer {
    padding-left: 1em;
  }

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  &__card-header {
    margin-bottom: 1.0625em;
  }

  &__headline {
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.44;
    letter-spacing: normal;
    color: var(--white);
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: calc(100% - 3rem);

    &:hover {
      color: var(--white-opacity);
    }
  }

  &__body {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--gap-S);
    border-radius: 0.625em;
    background: var(--card-background-primary);

    a:focus {
      outline: none;
      #{$block}__headline {
        color: var(--white-opacity);
      }
    }
  }

  &__private-badge {
    position: absolute;
    top: 1.65rem;
    left: 1.65rem;
    padding: 0em 0.2em;
    transform: skewX(-8deg);
    background: var(--white);
    font-size: 0.75em;
    font-weight: 800;

    line-height: 1em;

    color: var(--white);
  }
  &__private-label {
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 800;
    color: var(--black);
  }

  .card-checkbox {
    display: block;
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 2;

    @media screen and (min-width: $project-card-layout-switch) {
      top: 0em;
      right: 0em;
    }

    & label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 5em;
      height: 5em;
      padding-right: var(--gap-S);
      cursor: pointer;
    }

    & input {
      display: flex;
      justify-content: center;
      align-items: center;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid var(--white);
      border-radius: 50%;
      width: 1.8em;
      height: 1.8em;
      margin: 0;
      opacity: 0.5;
      cursor: pointer;
      transition: var(--hover-transition);

      &::after {
        content: '✓';
        display: none;
        color: var(--white);
        text-align: center;
      }

      &:hover {
        opacity: 1;

        &::after {
          display: block;
        }
      }

      &:checked {
        opacity: 1;
        background: var(--bright-green);

        &::after {
          display: block;
        }
      }

      &:focus {
        opacity: 1;
        outline: none;
        transition: none;
      }
    }
  }
}
