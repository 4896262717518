.session-sidebar {
  background: var(--gray-70);
  height: 100vh;
  position: sticky;
  top: 0;

  &__scroll-container {
    padding: 0 1.875rem 2rem 2.375rem;
    height: 100vh;
    overflow: auto;

    > *:last-child {
      margin-bottom: 2rem;
    }

    // Scrollbar styles
    &::-webkit-scrollbar {
      height: 0.5rem;
      width:  0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--gray-110);
      border: 1px solid var(--gray-180);
      border-radius: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-400);
      border-radius: 0.25rem;
      margin: 0.25rem;
    }
  }


  &__header {
    padding: 2rem 0 1rem;
    background: var(--gray-70);
    position: sticky;
    top:  0;
    z-index: 10;
  }
}
