.member-table {
  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    @media screen and (min-width: 46em) {
      flex-direction: row;
      margin-bottom: 1em;
    }
  }

  &__name,
  &__email,
  &__delete {
    display: inline-flex;
    align-items: center;
    background: var(--gray-250);

    .edit-button {
      margin-left: auto;
      margin-right: 0.7em;
      width: 2em;
      height: 2em;
    }

    @media screen and (min-width: 46em) {
      background: none;

      .edit-button {
        margin-left: 0.5em;
      }
    }
  }

  &__name {
    padding: 1em 0 0.5em 1em;
    min-width: max-content;
    flex-grow: 1;

    @media screen and (min-width: 46em) {
      padding: 1em 1em 1em 0;
    }
  }

  &__email {
    padding: 0.5em 0 1em 1em;
    max-width: fit-content;

    @media screen and (min-width: 46em) {
      padding: 1em;
    }
  }

  &__access {
    @media screen and (min-width: 46em) {
      margin-left: auto;
      min-width: max-content;
    }

    .edit-input__select-wrapper::after {
      top: 1.3em;
    }
  }

  &__delete {
    padding: 0.5em 0 1em 1em;

    @media screen and (min-width: 46em) {
      padding: 1em;
    }
  }

  &--higher-z-index {
    z-index: 1;
  }
}
