table.customer-list {
  border-collapse: separate;
  border-spacing: 0 30px; /* Horizontal and vertical spacing */
  tr {
    height: 78.75px;
  }
  thead {
    th {
      padding: 1.25rem;
      font-size: 1rem;
      font-weight: 200;
      color: var(--gray-500);
      text-align: left;
      background-color: var(--gray-250);
      cursor: pointer;
    }

    th:first-child {
      width: 0;
      border-top-left-radius: 0.714em;
      border-bottom-left-radius: 0.714em;
    }

    th:last-child {
      border-top-right-radius: 0.714em;
      border-bottom-right-radius: 0.714em;
    }

    th:not(:first-child) {
      padding-left: 1rem;
    }
  }

  td,
  th {
    background-color: var(--gray-500); /* or any color you prefer */
    border: 0px solid #ccc; /* Optional: adds border to each cell */
    padding: 0;
  }

  tbody {
    tr:nth-child(odd) {
      td {
        background-color: var(--gray-180);
      }
    }
    td:first-child {
      border-top-left-radius: 0.714em;
      border-bottom-left-radius: 0.714em;
    }

    td:last-child {
      border-top-right-radius: 0.714em;
      border-bottom-right-radius: 0.714em;
      padding-right: 1rem;
    }

    td:not(:first-child) {
      padding-left: 1rem;
    }
  }

  .customer-logo {
    cursor: pointer;
    width: 1%;
  }

  .customer-name {
    font-weight: 800;
    font-size: 1.25rem;
    font-style: italic;
    color: var(--text-color);
    cursor: pointer;
  }

  .customer-sector {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-color);
    font-style: italic;
  }

  .project-info {
    font-size: 1rem;
    font-weight: 200;
    color: var(--gray);
  }

  .customer-info-header {
    display: none;

    @media screen and (min-width: 46em) {
      display: table-header-group;
    }
  }
  .customer-info {
    display: none;

    @media screen and (min-width: 46em) {
      display: table-cell;
    }
  }

  .asc::after {
    content: url('data:image/svg+xml;utf8,<svg transform="rotate(180)" width="10" height="7" viewBox="0 0 8 5" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M0.94 0L4 3.09042L7.06 0L8 0.951417L4 5L0 0.951417L0.94 0Z" fill="white"/></svg>');
    padding-left: 0.625rem;
    top: -2px;
    position: relative;
  }

  .desc::after {
    content: url('data:image/svg+xml;utf8,<svg width="10" height="7" viewBox="0 0 8 5" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M0.94 0L4 3.09042L7.06 0L8 0.951417L4 5L0 0.951417L0.94 0Z" fill="white"/></svg>');
    padding-left: 0.625rem;
    top: -2px;
    position: relative;
  }

  .inactive {
    &.asc::after,
    &.desc::after {
      opacity: 0.1;
    }
  }
}
