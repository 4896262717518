.rainbow-card {
  $block: &;
  $rainbow-card-layout-switch: 56em;

  --text-color: var(--white);
  --card-background-primary: var(--gradient-primary);
  --card-background-secondary: var(--gray-250);
  --image-height: 15.5rem;

  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20.625rem;

  &--for-sidebar {
    #{$block}__image {
      margin-top: -2em;
      margin-right: -0.75em;

      &--second,
      &--third {
        display: block;
        position: absolute;
        margin-right: 0em;
      }

      &--second {
        right: -2.25em;
      }

      &--third {
        right: -3.75em;
      }
    }

    #{$block}__multiple-images {
      margin-right: 4.5em;
    }

    #{$block}__phone {
      width: 90%;
    }
  }

  // Apply Styles only if class "rainbow-card--unresponsive" is not present
  &:not(.rainbow-card--unresponsive) {
    @media screen and (min-width: $rainbow-card-layout-switch) {
      --image-height: 22.625rem;
      flex-direction: row-reverse;

      &#{$block}--draft {
        #{$block}__body {
          border-top-left-radius: 0;
          border-bottom-right-radius: 0.625em;
        }

        #{$block}__link {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0.625em;
        }
      }

      &#{$block}--with-image {
        #{$block}__body {
          padding-right: 20em;
          margin-right: -18em;
        }
      }

      &#{$block}--with-multiple-image {
        #{$block}__body {
          padding-right: 24em;
          margin-right: -22em;
        }
      }

      &#{$block}--with-top {
        padding-top: 6em;
      }

      #{$block}__image {
        margin-top: -2em;
        margin-right: 5.75em;

        &--second,
        &--third {
          display: block;
          position: absolute;
          margin-right: 0em;
        }

        &--second {
          right: 3.5em;
        }

        &--third {
          right: 1.5em;
        }
      }

      #{$block}__multiple-images {
        margin-right: 4em;
      }

      #{$block}__draft {
        writing-mode: vertical-lr;
        padding: 0 3px;
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0;
      }
    }
  }

  &--draft {
    #{$block}__body {
      //background: var(--card-background-secondary);
      opacity: 0.8;
      border-top-left-radius: 0.625em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    #{$block}__link {
      &:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &--shrink {
    flex-direction: column;
    min-height: auto;

    #{$block}__body {
      flex-grow: unset;
      width: 100%;
    }
  }

  &--pointer {
    cursor: pointer;
  }

  &--gray {
    #{$block}__body {
      background: var(--card-background-secondary);
    }
  }

  &--with-image {
    padding-top: 3em;
    min-height: 23.625rem;
  }

  &--with-multiple-image {
    padding-top: 3em;
  }

  &--with-top {
    padding-top: 1em;
  }

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  .card-header {
    margin-bottom: 0.4rem;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--gap-S);
    border-radius: 0.625em;
    background: var(--card-background-primary);
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }

  &__text {
    @include copy-small;
    padding: 0 0 1.214em;
  }

  &__multiple-images {
    position: relative;
    display: flex;
  }

  &__image {
    flex-basis: 12.25em;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    position: relative;
    z-index: 5;
    pointer-events: none;
    width: 12.25em;

    &--second,
    &--third {
      display: none;
      position: relative;
      bottom: 0;
    }

    &--second {
      flex-basis: 10.25em;
      width: 10.25em;
      z-index: 3;

      #{$block}__phone {
        max-height: calc(var(--image-height) - 1.5em);

        img {
          max-height: calc(var(--image-height) - 1.5em);
        }
      }
    }

    &--third {
      flex-basis: 9.25em;
      width: 9.25em;
      z-index: 2;

      #{$block}__phone {
        max-height: calc(var(--image-height) - 3em);

        img {
          max-height: calc(var(--image-height) - 3em);
        }
      }
    }
  }

  &__phone {
    width: 100%;
    max-height: var(--image-height);
    position: relative;

    img {
      width: 100%;
      max-height: var(--image-height);
      object-fit: cover;
      object-position: center top;
      border-radius: 2px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -0.65em;
      top: -0.5em;
      right: -0.65em;
      bottom: 0;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 448"><g fill="none" fill-rule="evenodd"><path fill="%23313142" d="M190.4 0a30 30 0 0130 30v69.5h.1c.8 0 1.5.7 1.5 1.5v27c0 .8-.7 1.5-1.5 1.5V418a30 30 0 01-30 30h-159a30 30 0 01-30-30V170.4h-.1c-.8 0-1.4-.7-1.4-1.5v-29c0-.9.7-1.5 1.5-1.5v-8.9h-.1c-.8 0-1.4-.7-1.4-1.5V99c0-.9.7-1.5 1.5-1.5V75.3h-.1c-.8 0-1.4-.7-1.4-1.5V60.3c0-.9.7-1.5 1.5-1.5V30a30 30 0 0130-30h159zM33 12a20 20 0 00-20 19.7V417a20 20 0 0019.7 20H189a20 20 0 0020-19.7V32a20 20 0 00-19.7-20h-19.5.2c-2 0-4.5.5-6 1.5-1.4 1-2 2.8-2 3.4v.1a6 6 0 01-6 6H67a6 6 0 01-6-5.8V17c0-.5-.5-2.5-2-3.5S55 12 53 12h.2H33z"/><path fill="%23D8D8D8" d="M127 11a3 3 0 110 6 3 3 0 010-6zm-12 2a1 1 0 110 2H94a1 1 0 010-2h21z"/></g></svg>');
      background-size: cover;
      box-shadow: 0 0.5em 2.8125em rgba(0, 0, 0, 0.1),
        0 0 1em rgba(0, 0, 0, 0.1) inset;
    }
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 0.625em;
    transition: box-shadow 0.2s ease-out;

    &:hover {
      box-shadow: 0 0 0 0.375em var(--white-opacity) inset;
    }

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.5em;
    }
  }

  .card-menu {
    margin-top: auto;
    padding-top: 1.5em;
    position: relative;
    z-index: 5;
  }

  .card-checkbox {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    & label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 5em;
      height: 5em;
      padding-right: var(--gap-S);
      cursor: pointer;
      background: transparent;
      border: none;
    }

    & input {
      display: flex;
      justify-content: center;
      align-items: center;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid var(--white);
      border-radius: 50%;
      width: 1.8em;
      height: 1.8em;
      margin: 0;
      opacity: 0.5;
      cursor: pointer;
      transition: var(--hover-transition);

      &::after {
        content: '✓';
        display: none;
        color: var(--white);
        text-align: center;
      }

      &:hover {
        opacity: 1;

        &::after {
          display: block;
        }
      }

      &:checked {
        opacity: 1;
        background: var(--bright-green);

        &::after {
          display: block;
        }
      }

      &:focus {
        outline: none;
        transition: none;
        opacity: 1;
      }
    }
  }

  &__draft {
    @include copy-small;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-background-secondary);
    padding: 3px 0;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s;
    z-index: 10;

    @media screen and (min-width: 60em) {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:hover {
    .destroy-button {
      visibility: visible;
      opacity: 1;
    }
  }

  @at-root .card-slider__item > &--with-top {
    #{$block}__body {
      min-height: 20.6em;
    }
  }

  &--clickable {
    .card-checkbox {
      left: 0;
      bottom: 0;

      & label {
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding-top: var(--gap-S);
      }
    }
  }
}
