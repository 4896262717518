.rating-slider {
  position: relative;
  width: 10em;
  height: auto;
  display: flex;
  align-items: center;
  color: var(--white-opacity);
  margin: 1em 0;

  &__title {
    position: absolute;
    top: -1.7em;
    @include h5;
    //transform: translateY(-110%);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background: var(--white-opacity);
    border-radius: 0.375em;
    height: 0.75em;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 0.75em;
    border-radius: 0.375em;
    z-index: 3;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 5"><g stroke="%23FFF"><line x1="2" x2="2" y1="0" y2="5" vector-effect="non-scaling-stroke" /><line x1="4" x2="4" y1="0" y2="5" vector-effect="non-scaling-stroke" /><line x1="6" x2="6" y1="0" y2="5" vector-effect="non-scaling-stroke" /></g></svg>');
    background-size: 100%;
  }

  &__meter {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    background: var(--gradient-primary);
    border-radius: 0.375em;
    height: 0.75em;
    z-index: 2;
  }

  &__handle {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 0.75em;
    background: transparent;
    outline: none;
    position: relative;
    z-index: 4;
    border: 0;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1.5em;
      height: 1.5em;
      border-radius: 0.125em;
      background: #fff;
      cursor: pointer;
      border: none;
      opacity: 0;
      outline: none;
      transition: opacity 0.3s ease-out;
    }

    &::-moz-range-thumb {
      width: 1.5em;
      height: 1.5em;
      border-radius: 0.125em;
      background: #fff;
      cursor: pointer;
      border: none;
      opacity: 0;
      outline: none;
      transition: opacity 0.3s ease-out;
    }
  }
}
