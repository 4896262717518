@mixin arrows($items-per-row) {
  $block: &;

  $thickness: 0.1rem;
  $radius: 2em;
  $grid-gap: 4.125em;
  $screen-margin-top: 4em;
  $screen-padding-top: 4em;
  $gap-between-arrow-and-screen: 1em;
  $head-size: #{$thickness * 5};
  $color: var(--bright-pink);

  $vertical-gap: $grid-gap + $screen-margin-top + $screen-padding-top;
  $horizontal-gap: $grid-gap;

  &__arrow {
    display: none;
  }

  @if $items-per-row == 1 {
    &:not(:last-child) {
      #{$block}__arrow {
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - #{calc($thickness / 2)});
        width: $thickness;
        height: $vertical-gap;
        background-color: #{$color};
      }
    }

    &:nth-last-child(2) {
      #{$block}__arrow {
        height: calc(
          #{$vertical-gap} - #{$head-size} - #{$gap-between-arrow-and-screen}
        );

        &-head {
          display: block;
          z-index: 1;
          position: absolute;
          top: 100%;
          left: calc(50% - #{$head-size});
          width: 0;
          height: 0;
          border: $head-size solid transparent;
          border-top: $head-size solid #{$color};
        }
      }
    }
  } @else {
    &:nth-child(#{$items-per-row - 1}n):not(:last-child),
    &:not(:last-child) {
      #{$block}__arrow {
        display: block;
        position: absolute;
        top: calc(
          50% + #{calc($screen-padding-top * 2)} - #{calc($thickness / 2)}
        );
        left: 100%;
        width: $horizontal-gap;
        height: $thickness;
        background-color: #{$color};

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &:not(:nth-child(#{$items-per-row - 1}n)):nth-last-child(2) {
      #{$block}__arrow-head {
        display: block;
        z-index: 1;
        position: absolute;
        top: 100%;
        left: calc(50% - #{$head-size});
        width: 0;
        height: 0;
        border: $head-size solid transparent;
        border-top: $head-size solid #{$color};
      }
    }

    &:not(:nth-child(#{$items-per-row}n)):nth-last-child(2) {
      #{$block}__arrow {
        width: calc(
          #{$horizontal-gap} - #{$head-size} - #{$gap-between-arrow-and-screen}
        );

        @media screen and (((min-width: 862px) and (max-width: 1127px)) or (min-width: 1224px)) {
          &-head {
            top: calc(50% - #{$head-size});
            left: calc(100%);
            border: $head-size solid transparent;
            border-left: $head-size solid #{$color};
          }
        }
      }
    }

    @media screen and (((min-width: 542px) and (max-width: 861px)) or ((min-width: 1128px) and (max-width: 1223px))) {
      &:nth-child(odd):nth-last-child(2) {
        #{$block}__arrow-head {
          left: 100% !important;
          border: $head-size solid transparent !important;
          top: calc(50% - #{$head-size}) !important;
          border-left: $head-size solid #{$color} !important;
        }
      }
    }

    &:nth-child(odd):nth-last-child {
      margin-top: 9em;
    }

    &:nth-child(#{$items-per-row - 1}n):not(:last-child) {
      &:nth-last-child(2) {
        #{$block}__arrow {
          top: calc(
            50% + #{calc($screen-padding-top * 2)} - #{calc($thickness / 2)}
          );
          height: $thickness;
        }
      }
    }

    &:nth-child(#{$items-per-row}n):not(:last-child) {
      &:nth-last-child(2) {
        #{$block}__arrow {
          top: calc(100% + (#{$head-size} + #{$gap-between-arrow-and-screen}));
          height: calc(
            #{$vertical-gap} -
              (#{$head-size} + #{$gap-between-arrow-and-screen}) *
              2
          );

          &::before {
            top: calc(#{-$head-size} - #{$gap-between-arrow-and-screen} - 1px);
            height: calc(
              50% + (#{$head-size} + #{$gap-between-arrow-and-screen})
            );
          }
        }
      }

      #{$block}__arrow {
        display: block;
        position: absolute;
        top: 100%;
        left: unset;
        right: calc(50% - #{$thickness});
        width: calc(
          100% *
            #{$items-per-row -
            1} +
            #{$horizontal-gap} *
            #{$items-per-row -
            1} +
            #{$thickness *
            2}
        );
        height: #{$vertical-gap};
        background-color: transparent;

        &-head {
          left: calc(#{-$head-size} + #{calc($thickness / 2)});
        }

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 50%;
          height: 50%;
          border: #{$thickness} solid #{$color};
        }

        &::before {
          box-sizing: content-box;
          right: 0;
          top: -1px;
          border-left: none;
          border-top: none;
          border-bottom-right-radius: #{$radius};
        }

        &::after {
          left: 0;
          bottom: 0;
          top: 50%;
          border-right: none;
          border-bottom: none;
          border-top-left-radius: #{$radius};
        }
      }
    }
  }
}

.l-screen-item {
  position: relative;
  padding-top: 16em;
  margin-top: 4em;

  @include arrows($items-per-row: 1);

  @media screen and (min-width: 542px) {
    @include arrows($items-per-row: 2);
  }

  @media screen and ((min-width: 862px) and (max-width: 1127px)) {
    @include arrows($items-per-row: 3);
  }

  @media screen and (min-width: 1128px) {
    @include arrows($items-per-row: 2);
  }

  @media screen and (min-width: 1224px) {
    @include arrows($items-per-row: 3);
  }

  &__headline {
    position: absolute;
    width: calc(100% + 0.75em * 2);
    top: 0;
    left: 50%;
    padding: 0.75em 1em;
    transform: translateX(-50%);
    border-radius: 0.625em;
    background-color: var(--gray-250);
    height: 14rem;

    &--placeholder {
      opacity: 0.5;
      font-style: italic !important;
    }

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .edit-input {
      text-align: left;
      font-size: 0.875em;

      input {
        height: 2.5em;
      }
    }

    h5 {
      text-transform: uppercase;
      font-weight: 800;
      font-style: italic;
    }

    .destroy-button {
      position: absolute;
      top: -1em;
      right: -1em;
      visibility: visible;
      opacity: 1;
      transition: all 0.2s;
      z-index: 10;

      @media screen and (min-width: 60em) {
        visibility: hidden;
        opacity: 0;
      }
    }

    &:hover {
      .destroy-button {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__description {
    font-size: 0.875em;
    line-height: 1.5;
    padding-top: 0.375em;
    height: 9.5em;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &--placeholder {
      opacity: 0.5;
      font-style: italic;
    }

    textarea {
      height: 9rem;
    }

    &--auto {
      height: auto;

      textarea {
        height: auto;
      }
    }
  }

  input {
    @include h2;
    text-transform: none;
    height: 1.5em;
    text-align: center;
  }
}
