.l-rainbow-edit-wrapper {
  $wrapper-breakpoint: 46em;
  $block: &;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -4.5% 0 -3.5%;

  @media screen and (min-width: 60em) {
    flex-direction: row;
  }

  .add-button, .file-preview {
    margin: 0 auto 2em auto;
  }

  &--settings {
    margin: 0;
    width: 100%;

    .add-button, .file-preview {
      margin-top: -0.4em;
    }

    #{$block}__body {
      margin: 0;

      @media screen and (min-width: 60em) {
        margin-left: 2.5%;
      }
    }
  }

  &__image {
    min-width: 14em;
    margin: 0 auto 3em auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 60em) {
      margin: 0 3.5% 0 4.5%;
    }

    > * {
      margin: 1em 0;

      &:first-child {
        margin-top: calc((0.625em / 2) * -1);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2.5%;
    margin-right: 3.5%;

    @media screen and (min-width: $wrapper-breakpoint) {
      margin-left: 3.5%;
      margin-right: 4.5%;
    }

    > * {
      margin: calc(0.625em / 2) 0;

      &:first-child {
        margin-top: calc((0.625em / 2) * -1);
      }
    }
  }

  .rainbow-card {
    min-height: 5em;
  }
}