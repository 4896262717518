$breakpoint: 43.75em;

.l-card-stack {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  gap: 0.5em;
  padding-top: 0.5em;

  @media (min-width: $breakpoint) {
    grid-template-columns: 18.6875rem 1fr;
  }

  &--no-padding {
    padding-top: 0;
  }

  .rainbow-card-small {
    // grid-row: 2;
    grid-column: 1;
    max-width: 100%;

    @media (min-width: $breakpoint) {
      max-width: 18.687rem;
      height: auto;
    }
  }

  .add-button {
    grid-column: 1;
    max-width: 100%;

    @media (min-width: $breakpoint) {
      grid-column: 1 / -1;
    }
  }

  .rainbow-card {
    &:first-child {
      grid-column: 1;

      @media (min-width: $breakpoint) {
        grid-column: 1 / -1;
      }
    }

    &:last-child {
      grid-column: 1;
      padding: 0;
      min-height: 20.625rem;

      @media (min-width: $breakpoint) {
        grid-column: 2 / -1;
      }

      .destroy-button {
        position: absolute;
        bottom: 22em;
        right: -0.7em;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s;
        z-index: 10;

        @media screen and (min-width: 60em) {
          visibility: hidden;
          opacity: 0;
        }
      }

      &:hover {
        .destroy-button {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .rainbow-card--with-image {
    --image-height: 18.625rem;

    .rainbow-card__image {
      margin: 0 auto;
      margin-top: 0.5em;
    }

    .rainbow-card__body {
      width: 100%;
      max-width: 100%;
    }
  }

  .card-wrapper {
    grid-column: 1;

    @media (min-width: $breakpoint) {
      grid-column: 1 / -1;
    }
  }

  .edit-input__input--simple-headline {
    margin: 0;
  }

  .edit-textarea__text {
    height: 14em;
  }
}
