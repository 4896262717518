.select-top {
  position: relative;
  grid-area: 1/1/2/5;
  display: flex;
  width: 100%;
  padding: 0.5em 2em 2em 2em;
  background: var(--bright-pink);
  border: none;
  border-radius: 0.714em;
  z-index: 0;
  align-items: center;

  &__title {
    display: flex;
    align-items: center;
    padding: 0 0.75em;
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
    border-radius: 0.714em;
    gap: 1.125em;
  }

  > .destroy-button, > .icon-button {
    position: absolute;
    top: 0.5em;
    right: 1em;
    padding: 0;
    z-index: 10;
    background: var(--gray-250);

    &:focus {
      outline: none;
    }
  }

  > .icon-button {
    right: 3.5em;
  }
}
