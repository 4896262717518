.point-navigation {
  position: fixed;
  top: 0;
  right: 24.5rem;
  bottom: 0;
  width: 0.75rem;
  display: flex;
  align-items: center;
  padding: 0;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;

    &:before {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      background: var(--bright-blue);
      border-radius: 50%;
      transition: 0.3s ease-out;
      transition-property: transform, opacity, box-shadow;
      opacity: 0.5;
    }

    &:hover:before,
    &.active:before {
      transform: scale(1.5);
      opacity: 1;
    }

    &:focus {
      outline: none;
      &:before {
        /* Provide a fallback style for browsers
        that don't support :focus-visible */
        background: var(--bright-pink);
      }
    }

    &:focus:not(:focus-visible):before {
      /* Remove the focus indicator on mouse-focus for browsers
      that do support :focus-visible */
      background: var(--bright-blue);
    }

    &:focus-visible:before {
      /* Draw a very noticeable focus style for
      keyboard-focus on browsers that do support
      :focus-visible */
      background: var(--bright-blue);
      box-shadow: 0 0 0 4px var(--bright-pink);
      opacity: 1;
    }
  }
}