.rainbow-card-small {
  $block: &;
  $rainbow-card-layout-switch: 46em;

  --text-color: var(--white);
  --card-background-primary: var(--gradient-primary);
  --card-background-secondary: var(--gray-250);
  --image-height: 17.5em;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.6875rem;
  height: 14.3125rem;

  @media screen and (min-width: $rainbow-card-layout-switch) {
    --image-height: 25.25em;
    flex-direction: row-reverse;
  }

  &--draft {
    #{$block}__body {
      //background: var(--card-background-secondary);
      opacity: 0.8;
      border-top-left-radius: 0.625em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @media screen and (min-width: $rainbow-card-layout-switch) {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0.625em;
      }
    }

    #{$block}__link {
      cursor: pointer;

      &:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        @media screen and (min-width: $rainbow-card-layout-switch) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0.625em;
        }
      }
    }
  }

  &--gray {
    #{$block}__body {
      background: var(--card-background-secondary);
    }
  }

  &--with-image {
    #{$block}__body {
      @media screen and (min-width: $rainbow-card-layout-switch) {
        padding-right: 20em;
        margin-right: -18em;
      }
    }
  }

  &--shrink {
    height: auto;
  }

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  .card-header {
    margin-bottom: 0.75em;
  }

  &__body {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--gap-XS) var(--gap-XS) 1em;
    border-radius: 0.625em;
    background: var(--card-background-primary);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 999;
    max-height: 10em;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }

  &__text {
    @include copy-small;
    padding: 0 0 1.214em;
  }

  &__image {
    flex-basis: 12.25em;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    pointer-events: none;
    width: 12.25em;

    @media screen and (min-width: $rainbow-card-layout-switch) {
      margin-top: -2em;
      margin-right: 5.75em;
    }
  }

  &__phone {
    width: 100%;
    max-height: var(--image-height);
    position: relative;

    img {
      width: 100%;
      max-height: var(--image-height);
      object-fit: cover;
      object-position: center top;
    }

    &:after {
      content: '';
      position: absolute;
      left: -0.75em;
      top: -0.625em;
      right: -0.75em;
      bottom: 0;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 448"><g fill="none" fill-rule="evenodd"><path fill="%23313142" d="M190.4 0a30 30 0 0130 30v69.5h.1c.8 0 1.5.7 1.5 1.5v27c0 .8-.7 1.5-1.5 1.5V418a30 30 0 01-30 30h-159a30 30 0 01-30-30V170.4h-.1c-.8 0-1.4-.7-1.4-1.5v-29c0-.9.7-1.5 1.5-1.5v-8.9h-.1c-.8 0-1.4-.7-1.4-1.5V99c0-.9.7-1.5 1.5-1.5V75.3h-.1c-.8 0-1.4-.7-1.4-1.5V60.3c0-.9.7-1.5 1.5-1.5V30a30 30 0 0130-30h159zM33 12a20 20 0 00-20 19.7V417a20 20 0 0019.7 20H189a20 20 0 0020-19.7V32a20 20 0 00-19.7-20h-19.5.2c-2 0-4.5.5-6 1.5-1.4 1-2 2.8-2 3.4v.1a6 6 0 01-6 6H67a6 6 0 01-6-5.8V17c0-.5-.5-2.5-2-3.5S55 12 53 12h.2H33z"/><path fill="%23D8D8D8" d="M127 11a3 3 0 110 6 3 3 0 010-6zm-12 2a1 1 0 110 2H94a1 1 0 010-2h21z"/></g></svg>');
      background-size: cover;
      box-shadow: 0 0.5em 2.8125em rgba(0, 0, 0, 0.1),
        0 0 1em rgba(0, 0, 0, 0.1) inset;
    }
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 0.625em;
    transition: box-shadow 0.2s ease-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 0.375em var(--white-opacity) inset;
    }

    &:focus {
      outline: 1px dashed var(--white-opacity);
      outline-offset: -0.5em;
    }
  }

  .card-menu {
    margin-top: auto;
    // padding-top: 1.5em;
    position: relative;
    z-index: 5;
  }

  .card-checkbox {
    display: block;
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 2;

    @media screen and (min-width: $rainbow-card-layout-switch) {
      top: 0em;
      right: 0em;
    }

    & label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 5em;
      height: 3.7rem;
      padding-right: var(--gap-S);
      cursor: pointer;
      background: transparent;
      border: none;
    }

    & input {
      display: flex;
      justify-content: center;
      align-items: center;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid var(--white);
      border-radius: 50%;
      width: 1.8em;
      height: 1.8em;
      margin: 0;
      opacity: 0.5;
      cursor: pointer;
      transition: var(--hover-transition);

      &::after {
        content: '✓';
        display: none;
        color: var(--white);
        text-align: center;
      }

      &:hover {
        opacity: 1;

        &::after {
          display: block;
        }
      }

      &:checked {
        opacity: 1;
        background: var(--bright-green);

        &::after {
          display: block;
        }
      }

      &:focus {
        outline: none;
        transition: none;
        opacity: 1;
      }
    }
  }

  &__draft {
    @include copy-small;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-background-secondary);
    padding: 3px 0;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;

    @media screen and (min-width: $rainbow-card-layout-switch) {
      writing-mode: vertical-lr;
      padding: 0 3px;
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0;
    }
  }

  .destroy-button {
    position: absolute;
    top: -0.7em;
    right: -0.7em;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s;
    z-index: 10;

    @media screen and (min-width: 65em) {
      visibility: hidden;
      opacity: 0;
    }
  }

  .typography {
    display: -webkit-box;
    overflow: hidden;
    margin: 0;
    line-height: 1.625em;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 8.25em;
    text-overflow: ellipsis;
  }

  &:hover {
    .destroy-button {
      visibility: visible;
      opacity: 1;
    }
  }
}
