.tab-container {
  &__button-container {
    display: flex;
    flex-direction: row;
    padding: 5em 0 0;
  }

  .section-headline {
    display: none;
  }

  &__button {
    @include h2;
    color: var(--text-color-secondary);
    cursor: pointer;
    background: transparent;
    line-height: 1;
    margin-right: 0;
    padding: 1em;
    white-space: nowrap;

    @media screen and (min-width: 46em) {
      margin-right: 1em;
    }

    &--active {
      color: var(--text-color);
      border-bottom: 1px solid var(--highlight);
    }

    &:hover {
      color: var(--text-color);
    }

    &:focus {
      outline: none;
      color: var(--text-color);
    }
  }
}
