.l-content-wrapper {
  $content-breakpoint: 46em;

  display: grid;
  grid-template-columns: 1fr minmax(auto, 54em) 1fr;
  width: 100%;
  // max-width: 75em;
  margin: 0 auto;
  padding: 4em 1em 4em;
  background: var(--background-first);

  @media screen and (min-width: $content-breakpoint) {
    padding: 5em 2em 4em;
  }

  &--small {
    padding: 2em 1em 4em;
  }

  &--big {
    padding: 7em 1em 4em;

    > .search-form {
      margin-top: -8.5em;
    }
  }

  &--wide {
    grid-template-columns: 1fr minmax(auto, 74em) 1fr;
    max-width: none;
  }

  &--ultra {
    width: calc(100% + 6px);
    padding: 0 0 4em;
    max-width: none;
  }

  > .tag-list {
    margin-top: -6.5em;

    @media screen and (min-width: $content-breakpoint) {
      margin-top: -7.5em;
    }
  }

  > .faq-slider {
    margin-top: -6.5em;

    @media screen and (min-width: $content-breakpoint) {
      margin-top: -7.5em;
    }
  }

  > * {
    grid-column: 2;
  }

  &--extend {
    grid-column: 1/-1;
  }
}
