.bubble {
  display: grid;
  place-items: center;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.285;
  color: #ffff;
  min-width: 10ch;
  background: rgba(144, 19, 254, 0.9);
  padding: 0.5em 1.42em;
  max-width: 14em;
  border-radius: 99rem;
  height: 3.57em;

  &:before {
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;
    right: 3em;
    bottom: -1em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'%3E%3Cpath fill='%239013FE' fill-opacity='.9' fill-rule='evenodd' d='M0 0c1 2 3 5 3 10L13 0H0z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 100% 100%;
  }

  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.57em;
  }
}
