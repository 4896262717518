.network-company-slider {
  &--spacing {
    margin-bottom: 6em;
  }

    &__slide {
        width: 100%;
        height: 100%;
        display: grid;
        overflow: hidden;
        background: var(--gray-180);
        border-radius: 0.625em;
        grid-gap: calc(var(--gap-S) / 2);
        padding: calc(var(--gap-S) / 2);
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(1, 1fr);

        @media (min-width: 43.0625em) {
            padding: var(--gap-S);
            grid-gap: var(--gap-S);
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 58.8125em) {
            grid-template-columns: repeat(3, 1fr);
        }

        a {
          text-decoration: none;
        }
    }

    &__entry {
        $block: &;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .company-logo {
          align-self: center;
          margin-right: calc(var(--gap-S) / 2);
        }

        &-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 9999;
        }
      
        &-title {
          @include h1;
          font-size: 1em;
          color: var(--text-color);
        }
      
        &-subtitle {
          @include h2;
          line-height: 1;
          text-transform: unset;
          margin-top: 0.5em;
          font-style: normal;
          font-weight: normal;
          color: var(--white-opacity);
          font-size: 0.75em;
          transition: var(--hover-transition);
        }

        &:hover {
          #{$block}-subtitle {
            color: var(--white);
          }
        }

        @media (min-width: 26.3125em) {
          &-title {
            font-size: 1.125em;
          }
        
          &-subtitle {
            font-size: 1em;
          }

          .company-logo {
            margin-right: var(--gap-S);
          }
        }
    }
}