.labeled-icon {
  --background: var(--icon--background, var(--text-color));
  --color: var(--icon-color, var(--background-third));
  --size: var(--icon-size, 2.5em);
  --opacity: var(--icon-opacity);
  --radius: var(--icon-radius, 0.625em);

  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: var(--opacity);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    border-radius: var(--radius);
    background: var(--background);
    color: var(--color);

    svg {
      font-size: 1.5em;
    }
  }

  &__label {
    @include h5;
    line-height: 1.18em;
    margin-top: 0.5em;
  }
}
