.progress {
  --progress-indicator: 0;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0.5rem;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 0.5rem;
    width: calc(var(--progress-indicator) * 1%);
    background: var(--bright-blue);
  }
}
