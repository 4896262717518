.page-logoEdit {
  display: flex;
  align-items: center;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 9999;
  }
  
  &__title {
    @include large-title;
  }

  &__subtitle {
    @include h2;
    line-height: 1;
    margin-top: 0.5em;
  }

  .file-upload, .file-preview {
    align-self: center;
    margin-right: var(--gap-S);
  }
}